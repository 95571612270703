'use client';
import setHours from 'date-fns/setHours';
import getHours from 'date-fns/getHours';

/**
 * Get the time with AM and PM reversed.
 *
 * @param date - The date to reverse the time meridian.
 * @returns The date with the time meridian reversed.
 */
export var getReversedTimeMeridian = function getReversedTimeMeridian(date) {
  var clonedDate = new Date(date.valueOf());
  var hours = getHours(clonedDate);
  var nextHours = hours >= 12 ? hours - 12 : hours + 12;
  return setHours(clonedDate, nextHours);
};
export default getReversedTimeMeridian;