import { Text, View, StyleSheet ,ActivityIndicator, FlatList } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import axios from 'axios';
import {
    useToaster,
    Message,
    Button,
    Form,
    SelectPicker,
    Checkbox,
    Pagination,
    IconButton,
    Table,
    List,
    Panel
} from 'rsuite';
import ExportData from '../Components/TableManagement/ExportData';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import configdata from '../config/config.json';
import {useUserToken} from "../hooks/useUserToken";
import { useMessage } from '../hooks/useMessage';
import "rsuite/dist/rsuite.min.css";
import CustomText from "../Components/CustomText";
import {useDropdownData} from "../hooks/useDropdownData";

const CarLogShower = ({ route }) => {

    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [araçDurumlar,setAraçDurumlar] = useState([]);
    const {getParseAraçDurum} = useDropdownData();


    useEffect(() => {
        fetchLogs();
        getParseAraçDurum(setAraçDurumlar);

    }, []);

    useEffect(() => {
        console.log(araçDurumlar);
    }, [araçDurumlar]);


    const {fetchIdToken} = useUserToken();

    const fetchLogs = async () => {
        const api = configdata.API_BASE + '/cardata'
        const idToken = await fetchIdToken();
        const headers = {
            "Authorization" : `Bearer ${idToken}`,
        }

        const body ={
            "ENV":  configdata.APP_ENV,
            "type" : 'GET_CAR_LOGS'
        }
        axios.post (api, body, {headers: headers}).then((response) => {
            console.log(response.data);
            setLogs(response.data);
            setLoading(false);

        }
        ).catch((error) => {
            setError(error.message);
            setLoading(false);
        });
    };


    const renderItem = ({ item }) => (
        <View style={styles.logItem}>
            <CustomText style={styles.logText}><CustomText fontWeight = {800}>Log:</CustomText> {item.LOG}</CustomText>
            <CustomText style={styles.logText}><CustomText fontWeight = {800} style={styles.label}>Type:</CustomText> {item.LOG_TYPE}</CustomText>
            <CustomText style={styles.logText}><CustomText fontWeight = {800} style={styles.label}>Date:</CustomText> {new Date(item.LOG_DATE).toLocaleString()}</CustomText>
            <CustomText style={styles.logText}><CustomText fontWeight = {800} style={styles.label}>PLAKA:</CustomText> {item.PLAKA}</CustomText>
            <CustomText style={styles.logText}><CustomText fontWeight = {800} style={styles.label}>Kullanıcı:</CustomText> {item.USER}</CustomText>
        </View>
    );

    if (loading) {
        return (
            <View style={styles.loadingContainer}>
                <ActivityIndicator size="large" color="#0000ff" />
            </View>
        );
    }

    if (error) {
        return (
            <View style={styles.errorContainer}>
                <CustomText style={styles.errorText}>{error}</CustomText>
                <Button mode="contained" onPress={fetchLogs} style={styles.retryButton}>
                    Retry
                </Button>
            </View>
        );
    }

    const columns = [
        {
            name: 'NO',
            align: 'center',
            fixed: true,
            width: 100,
        },
        {
            name: 'LOG_DATE',
            align: 'center',
            fixed: true,
            width: 100,
        },
        {
            name: 'PLAKA',
            align: 'center',
            fixed: true,
            width: 100,
        },
        {
            name: 'USER',
            align: 'center',
            fixed: true,
            width: 100,
        },
        {
            name: 'LOG_TYPE',
            align: 'center',
            fixed: true,
            width: 100,
        },
    ];



    return (
        <View style={styles.container}>

            <View style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 20, backgroundColor: 'white', padding: 20}}>
                <CustomText fontSize = {24} fontWeight={800}> Loglar </CustomText>
            </View>
            {/*<FlatList*/}
            {/*    data={logs}*/}
            {/*    keyExtractor={(item) => item.NO.toString()}*/}
            {/*    renderItem={renderItem}*/}
            {/*    contentContainerStyle={styles.listContainer}*/}
            {/*/>*/}

            <Panel style={{marginBottom: 20 , padding: 20 , backgroundColor: 'white'}} shaded >
                <List style={{width : 200}}>
                    {araçDurumlar.map((item, index) => {
                            return(
                            <List.Item key={index} index={index}>
                                <View style={{flexDirection: 'row' , justifyContent: 'space-between'}}>
                                <CustomText >{item.label}</CustomText>
                                <CustomText>{" -- "}</CustomText>
                                <CustomText>{item.value}</CustomText>
                                </View>
                            </List.Item>
                            )

                        }
                    )}
                </List>

            </Panel>
            <Panel style={{marginBottom: 20 , padding: 20 , backgroundColor: 'white'}} shaded >
                <ExportData data={logs} />

            <Table
                autoHeight={true}
                data={logs}
                onRowClick={data => {
                    console.log(data);
                }}
            >
                {columns.map((column, index) => {
                    return <Table.Column key={index} {...column}  flexGrow={1}>
                        <Table.HeaderCell>{column.name}</Table.HeaderCell>
                        <Table.Cell dataKey={column.name} />
                    </Table.Column>

                }
                )}
                <Table.Column flexGrow={3} fixed="right">
                    <Table.HeaderCell>Log</Table.HeaderCell>
                    <Table.Cell dataKey="LOG" />
                </Table.Column>
            </Table>

            </Panel>

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f5f5f5',
        padding: 16,
    },
    header: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 16,
        textAlign: 'center',
    },
    listContainer: {
        paddingBottom: 16,
    },
    logItem: {
        backgroundColor: '#fff',
        padding: 16,
        marginBottom: 8,
        borderRadius: 8,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 2,
    },
    logText: {
        fontSize: 14,
        marginBottom: 4,
    },
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    errorContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
    },
    errorText: {
        color: 'red',
        fontSize: 16,
        marginBottom: 16,
        textAlign: 'center',
    },
    retryButton: {
        marginTop: 8,
    },
});

export default CarLogShower;
