import { Text, View ,ScrollView, StyleSheet} from 'react-native'
import React, { useState ,useEffect , useRef } from 'react';
import { useToaster,Message,Button,Table,Column,Input, Container,IconButton,Drawer, Modal, SelectPicker ,Loader,List, MaskedInput} from 'rsuite';
import * as XLSX from 'xlsx';
import axios from 'axios';
import FileUploader from '../../Components/FileManagements/FileUploader';
import NewCarLabel from './NewCarLabel';
import configdata from '../../config/config.json';
import ExportData from '../../Components/TableManagement/ExportData';
import 'typeface-roboto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import  {useUserToken}  from '../../hooks/useUserToken';
import  {useUtils,useValidationRules} from '../../hooks/useUtils';
import  {useDropdownData} from '../../hooks/useDropdownData';
import EditCarScreen from './EditCarScreen';
import CreateExcelTemplate from '../../Components/CarAndFileManagement/CreateExcelTemplate';

const MultipleCarScreen  = () => {


    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }
    const importOptions = [
      { value: 'FRESH', label: 'TAZE STOK' },
      { value: 'STANDARD', label: 'STANDARD' },
    ]


    const [file, setFile] = useState(undefined);
    const [optionsCarTags,setOptionsCarTags] = useState([]);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    const [editRow, setEditRow] = useState(null);
    const [rows, setRows] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [wrongRows,setWrongRows] = useState([]);
    const [correctRows,setcorrectRows] = useState([]);
    const [hasDup,setHasDup] = useState(false);
    const [importType,setImportType] = useState("STANDARD"); // STANDARD , FRESH , UPDATE


    const [optionsBMP,setOptionsBMP] = useState([]);
    const [carBodyStyles,setCarBodyStyles] = useState([]);
    const [factory_years,setfactory_years] = useState([]);
    const [fuel_types,setFuel_types] = useState([]);
    const [cc_types,setcc_types] = useState([]);
    const [carOwners,setCarOwners] = useState([]);
    const [colors,setColors] = useState([]);
    const [km_periods,setKm_periods] = useState([]);
    const [transmissions,settransmissions] = useState([]);
    const [araçDurums,setAraçDurums] = useState([]);
    const [buydeps,setBuydeps] = useState([]);
    const [carBuyTypes,setCarBuyTypes] = useState([]);
    const [arrayForBuyTypes, setArrayBuyTypes] = useState([]);
    const [arrayForInsurenceFirms,setInsurenceFirmsAll] = useState([]);
    const [arrayForAgentFirms,setAgentFirmsAll] = useState([]);
    const [arrayForClients,setClients] = useState([]);






    const {getClients,getDropDownValues,getDropDownValuesGlobal,getDropDownValuesService,getBrands,getParseAraçDurum,DurumData,taahhütMonthsData,taahhütLogicData,taahhütKmData,
      kaskoTipiData,alisKanallari} = useDropdownData();

    const {validatePlateNumber, validateStandard ,ValidateBrandModelPaket, validateStandardBMP, validateStandardWithID ,validateStandardBMPID} = useValidationRules();
    const {ExcelDateToJSDate} = useUtils();
    const {fetchIdToken} = useUserToken();
    // useEffect(() => { if(rows.length > 0 && rows != undefined && rows != null){
    //   validateRows(rows,headers);
    // }},[rows])

    useEffect(() => {
      console.log("useEffect");
      getBrands(setOptionsBMP);

      getDropDownValues("CAR_BODY_STYLES",setCarBodyStyles);
      getDropDownValues("SERVICE_KM_PERIOD",setKm_periods);
      getDropDownValues("TRANSMISSIONS",settransmissions);
      getDropDownValues("FUEL_TYPES",setFuel_types);
      getDropDownValues("CC_TYPES",setcc_types);

      getDropDownValues("ARAÇ_DURUM",setAraçDurums);
      getDropDownValuesGlobal("CAR_OWNER",setCarOwners);
      getDropDownValuesGlobal("COLOR",setColors);
      getDropDownValuesGlobal("BUY_DEPARTMENT",setBuydeps);
      getDropDownValuesGlobal("CAR_BUY_TYPE",setCarBuyTypes);
      getDropDownValuesService("GET_LAST_50_YEARS",setfactory_years);
      getDropDownValuesGlobal("BUY_TYPE",setArrayBuyTypes);
      getDropDownValuesGlobal("INSURENCE_FIRM",setInsurenceFirmsAll);
      getDropDownValuesGlobal("AGENT_FIRM",setAgentFirmsAll);
      getClients(setClients);

    },[])

    const columns = [
      { key: 'PLAKA', name: 'PLAKA' },
         { key: 'TESCİL TARİHİ', name: 'TESCİL TARİHİ' },
      { key: 'İLK TESCİL TARİHİ', name: 'İLK TESCİL TARİHİ' },
      { key: "ARAÇ ALIŞ DEPARTMANI_NAME" , name : "ARAÇ ALIŞ DEPARTMANI"},
      { key: "ARAÇ EDİNİM ŞEKLİ_NAME" , name : "ARAÇ EDİNİM ŞEKLİ"},
      { key: 'MARKA_NAME' , name: 'MARKA' },
      { key : 'MODEL_NAME' , name :"MODEL" },
      { key : 'PAKET_NAME' , name :"PAKET" },
      { key : 'KASA_NAME' , name :"KASA" },
      { key : 'EKSTRA DONANIM_NAME' , name :"EKSTRA DONANIM" },
      { key : 'YAKIT TİPİ_NAME' , name :"YAKIT" },
      { key : 'CC_NAME' , name :"CC" },
      { key : 'VİTES_NAME' , name :"VİTES" },
      { key : 'RENK_NAME' , name :"RENK" },
      { key : 'BAKIM KM PERİYODU_NAME' , name :"BAKIM KM PERİYODU" },
      { key : 'RUHSAT SAHİBİ_NAME' , name :"RUHSAT SAHİBİ"},
      { key : 'MODEL YILI_NAME' , name :"ARAÇ DURUMU"},


      // { key: 'MARKA', name: 'MARKA' },
      // { key: 'MODEL', name: 'MODEL' },
      // { key: 'PAKET', name: 'PAKET' },
      // { key: 'KASA', name: 'KASA' },
      // { key: 'BAKIM KM PERİYODU', name: 'BAKIM KM PERİYODU' },
      // { key: 'VİTES', name: 'VİTES' },
      // { key: 'YAKIT TİPİ', name: 'YAKIT TİPİ' },
      // { key: 'CC', name: 'CC' },
      // { key: 'MODEL YILI', name: 'MODEL YILI' },
      { key: 'ARAÇ KM', name: 'ARAÇ KM' },
      { key: 'CC SINIFI', name: 'CC SINIFI' },
      { key: 'HP', name: 'HP' },
      { key: "TAAHHUT DURUMU_NAME" , name : "TAAHHUT DURUMU"},
      { key: "TAAHHUT_DATA" , name : "TAAHHUT"},
      // { key: 'KW', name: 'KW' },
      { key: 'ŞASİ', name: 'ŞASİ' },
      { key: 'MOTORNO', name: 'MOTORNO' },
      { key: 'RENK_NAME', name: 'RENK' },
      { key: 'MUAYENE TARİHİ', name: 'MUAYENE TARİHİ' },
      { key: 'TESCİL BELGE NO', name: 'TESCİL BELGE NO'},
      { key: 'RUHSAT SAHİBİ_NAME', name: 'RUHSAT SAHİBİ' },
      { key: 'MARKA KODU' , name: 'MARKA KODU'},
      { key: 'TİP KODU' , name: 'TİP KODU'},
      { key: 'SATIN ALIM ŞEKLİ_NAME' , name: 'SATIN ALIM ŞEKLİ'},
      { key: 'SATIN ALIM KANALI_NAME' , name: 'SATIN ALIM KANALI'},
      { key: 'TEDARİKÇİ_NAME' , name: 'TEDARİKÇİ'},
    { key: 'ALIŞ BİLGİSİ DURUMU_NAME' , name: 'ALIŞ BİLGİSİ DURUMU'},
    { key: 'ÖTV KDV DAHİL TUTAR' , name: 'ÖTV KDV DAHİL TUTAR'},
    { key: 'ÖTV KDV HARİÇ TUTAR' , name: 'ÖTV KDV HARİÇ TUTAR'},
    { key: 'ÖTV TUTARI' , name: 'ÖTV TUTARI'},
    { key: 'DİĞER FATURALI KDVSİZ TUTAR' , name: 'DİĞER FATURALI KDVSİZ TUTAR'},
    { key: 'DİĞER FATURASIZ TUTAR' , name: 'DİĞER FATURASIZ TUTAR'},
    ];
    //#endregion

    //#region Validation
    const handleFileUpload = async (file) => {
      // const file = e.target.files[0];
      const reader = new FileReader();
      console.log(optionsBMP);
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        const headers = data[0];
        const rows = data.slice(1);
        const nonEmptyRows = rows.filter(row => row.some(cell => cell !== null && cell !== ''));
        const CRows = [];
        const fixNeededRows = [];

        const {SSF} = XLSX;
        let columnsTemp = [];
        console.log(carBodyStyles);
        headers.forEach((header, index) => {
          if (header !== undefined && header !== "CHECKED") {
              columnsTemp.push(
                { key: header, name: header }
              );
          }
        });
        setHeaders(headers);
        // setColumns(columnsTemp);
        // const validatedRows =
        let counter = 0;
        nonEmptyRows.map(row => {
          const rowObject = {};
          rowObject["Fix Needed"] = "";
          rowObject["ID"] = counter;
          headers.forEach((header, index) => {
                rowObject[header] = row[index];
                if(Object.keys(validateNeedColumns).includes(header)){

                  if(header == "MARKA" || header == "MODEL" || header == "PAKET"){
                      let result = "";

                      if(header == "MARKA"){
                        result =  validateStandardBMP(row[index],optionsBMP);
                      }
                      if(header == "MODEL" ){
                        if(rowObject["MARKA_RDS_ID"] == undefined || rowObject["MARKA_RDS_ID"] == null || rowObject["MARKA_RDS_ID"] == ""){
                            editFixNeed(rowObject,"Hatalı " + header);
                        }
                        else{
                          result = validateStandardBMP(row[index],optionsBMP.find((element) => element.id === rowObject["MARKA_RDS_ID"]).children);
                        }
                      }

                      if(header == "PAKET"){
                        if(rowObject["MODEL_RDS_ID"] == undefined || rowObject["MODEL_RDS_ID"] == null || rowObject["MODEL_RDS_ID"] == ""){
                          editFixNeed(rowObject,"Hatalı " + header);
                        }
                        else{
                          result = validateStandardBMP(row[index],optionsBMP.find((element) => element.id === rowObject["MARKA_RDS_ID"]).children.find((element) => element.id === rowObject["MODEL_RDS_ID"]).children);
                        }
                      }
                      if(!result.isValid){
                        editFixNeed(rowObject,"Hatalı " + header);
                      }else{
                        rowObject[header] = result.id;
                        rowObject[header + "_NAME"] = result.label;
                        rowObject[header + "_RDS_ID"] = result.id;
                      }
                  }
                  if(header == "EKSTRA DONANIM"){
                    let result = {"isValid" : true}
                    console.log(row[index]);
                    if(row[index] != undefined && row[index] != "" && row[index] != null){
                      if(rowObject["MARKA_RDS_ID"] == undefined || rowObject["MARKA_RDS_ID"] == null || rowObject["MARKA_RDS_ID"] == ""){
                        editFixNeed(rowObject,"Hatalı " + header);
                      }
                      else{
                        result = validateStandardWithID(row[index],optionsBMP.find((element) => element.id === rowObject["MARKA_RDS_ID"]).childrenExtras);
                      }
                    }
                    if(!result.isValid){
                      editFixNeed(rowObject,"Hatalı " + header);
                      console.log("HATALI EKSTRA DONANIM");
                    }else{
                      rowObject[header] = result.id;
                      rowObject[header + "_NAME"] = result.label;
                      rowObject[header + "_RDS_ID"] = result.id;
                    }
                  }
                  if(validateNeedColumns[header].type === "LIST" && header != "MARKA" && header != "MODEL" && header != "PAKET" && header != "EKSTRA DONANIM")
                  {
                      // console.log(rowObject);
                      let result = validateStandard(tryFix(row[index],header),validateNeedColumns[header].list);
                      if(!result.isValid){
                        editFixNeed(rowObject,"Hatalı " + header);
                      }else{
                        rowObject[header] = result.id;
                        rowObject[header + "_NAME"] = result.label;
                        rowObject[header + "_RDS_ID"] = result.id;
                      }
                  }

                  if(validateNeedColumns[header].type === "PLAKA"){
                      let result = validatePlateNumber(row[index]);
                      if(!result.isValid){
                        editFixNeed(rowObject,"Hatalı " + header);
                      }else{
                        rowObject[header] = result.plateNumber;
                      }
                  }
                  if(validateNeedColumns[header].type === "DATE"){
                    if(row[index] != undefined && row[index] != ""){
                        // console.log(row[index]);
                        let temp = ExcelDateToJSDate(row[index]);
                        if(temp != null && temp != undefined && temp != "" && temp != "Invalid Date"){
                          rowObject[header] = temp.toLocaleDateString('en-US');
                        }else{
                          editFixNeed(rowObject,"Hatalı " + header);
                          rowObject[header] = null;
                        }

                    }
                  }

                  if(validateNeedColumns[header].type === "STRING_REQUIRED"){
                    if(row[index] == undefined || row[index] == null || row[index] == ""){
                      editFixNeed(rowObject,"Hatalı " + header);
                    }
                  }
                  if(validateNeedColumns[header].type === "LIST_NULLABLE"){
                      if(row[index] != undefined && row[index] != ""){
                        let result = validateStandard(row[index],validateNeedColumns[header].list);
                        if(!result.isValid){
                          editFixNeed(rowObject,"Hatalı " + header);
                        }else{
                          rowObject[header] = result.id;
                          rowObject[header + "_NAME"] = result.label;
                          rowObject[header + "_RDS_ID"] = result.id;
                        }
                      }
                  }
                  if(validateNeedColumns[header].type == "TL_FORMAT"){
                    if(row[index] != undefined && row[index] != ""){
                      rowObject[header] = Number(row[index].toString().replace("TL","").replace(" ","").replace(".","").replace(",","."));
                    }
                  }
                }
          });


          // Validate plate number, or any other parameters , send Validated to CRows and error to fixNeededRows

            if( rowObject["Fix Needed"] == undefined || rowObject["Fix Needed"] == null || rowObject["Fix Needed"] == ""){
              CRows.push(rowObject);
            }else{

              fixNeededRows.push(rowObject);
            }
            counter++;
          });
          console.log(CRows);
          console.log(fixNeededRows);
          setcorrectRows(CRows);
          setWrongRows(fixNeededRows);
        // setRows(validatedRows);
      };

      reader.readAsBinaryString(file);
    };


    function tryFix(input,header){
      if(header == 'CC' && input != undefined){
        if(input.toString().length == 2 && !isNaN(input)){
          return Number(input.toString().charAt(0) + "." + input.toString().charAt(1));
        }
        if(input.toString().length == 3 && !isNaN(input)){
          return Number(input);
        }
      }
      if(header == 'PLAKA' && input != undefined){
        if(input[input.toString().length-1] == " " ){
          return input.toString().substring(0,input.toString().length-1);
        }
        return input.toString().toUpperCase();
      }

      // if cc , and length = 2 and contains only numbers it is fixable by adding '.' on the middle

      return input;
    }


    const validateNeedColumns = {
      "MARKA" : {"type" : "LIST" , "list" : optionsBMP, function : ValidateBrandModelPaket},
      "MODEL" : {"type" : "LIST" , "list" : optionsBMP, function : ValidateBrandModelPaket},
      "PAKET" : {"type" : "LIST" , "list" : optionsBMP, function : ValidateBrandModelPaket},
      "EKSTRA DONANIM" : {"type" : "LIST" , "list" : optionsBMP, function : validateStandard},
      "PLAKA": {"type" : "PLAKA", function : validatePlateNumber},
      "KASA" : {"type" : "LIST" , "list" : carBodyStyles, function : validateStandard},
      "VİTES" : {"type" : "LIST" , "list" : transmissions, function : validateStandard},
      "YAKIT TİPİ" : {"type" : "LIST" , "list" : fuel_types, function : validateStandard},
      "CC" : {"type" : "LIST" , "list" : cc_types, function : validateStandard},
      "RENK" : {"type" : "LIST" , "list" : colors, function : validateStandard},
      // "BAKIM KM PERİYODU" : {"type" : "LIST" , "list" : km_periods, function : validateStandard},
      "RUHSAT SAHİBİ" : {"type" : "LIST" , "list" : carOwners, function : validateStandard},
      "MODEL YILI" : {"type" : "LIST" , "list" : factory_years, function : validateStandard},
      "ARAÇ DURUM" : {"type" : "LIST" , "list" : araçDurums, function : validateStandard},
      "TESCİL TARİHİ" : {"type" : "DATE" },
      "MUAYENE TARİHİ" : {"type" : "DATE" },
      "İLK TESCİL TARİHİ" : {"type" : "DATE" },
      "ALIŞ TARİHİ" : {"type" : "DATE" },
      "ŞASİ": {"type" : "STRING_REQUIRED" },
      "ARAÇ ALIŞ DEPARTMANI" : {"type" : "LIST" , "list" : buydeps, function : validateStandard},
      "ARAÇ EDİNİM ŞEKLİ" : {"type" : "LIST" , "list" : carBuyTypes, function : validateStandard},
      "TAAHHUT DURUMU" : {"type" : "LIST" , "list" : DurumData, function : validateStandard},
      "TAAHHUT AYI" : {"type" : "LIST_NULLABLE" , "list" : taahhütMonthsData, function : validateStandard},
      "VE/VEYA" : {"type" : "LIST_NULLABLE" , "list" : taahhütLogicData, function : validateStandard},
      "TAAHHUT KM" : {"type" : "LIST_NULLABLE" , "list" : taahhütKmData, function : validateStandard},
      "ALIŞ BİLGİSİ DURUMU" : {"type" : "LIST_NULLABLE" , "list" : DurumData, function : validateStandard},
      "SATIN ALIM ŞEKLİ" : {"type" : "LIST_NULLABLE" , "list" : arrayForBuyTypes, function : validateStandard},
      "SATIN ALIM KANALI" : {"type" : "LIST_NULLABLE" , "list" : alisKanallari, function : validateStandard},
      "TEDARİKÇİ" : {"type" : "LIST_NULLABLE" , "list" : arrayForClients, function : validateStandard},
      "ÖTV KDV DAHİL TUTAR" : {"type" : "TL_FORMAT" },
      // "KASKO TİPİ" : {"type" : "LIST" , "list" : kaskoTipiData, function : validateStandard},
      // "KASKO FİRMASI" : {"type" : "LIST_NULLABLE" , "list" : arrayForInsurenceFirms, function : validateStandard},
      // "KASKO ACENTE" : {"type" : "LIST_NULLABLE" , "list" : arrayForAgentFirms, function : validateStandard},
      // "TRAFİK SİGORTA FİRMASI" : {"type" : "LIST_NULLABLE" , "list" : arrayForInsurenceFirms, function : validateStandard},
      // "TRAFİK SİGORTA ACENTE" : {"type" : "LIST_NULLABLE" , "list" : arrayForAgentFirms, function : validateStandard},
      // "TRAFİK SİGORTA DURUMU" : {"type" : "LIST" , "list" : DurumData, function : validateStandard},
      // "TRAFİK SİGORTA BAŞLANGIÇ TARİHİ" : {"type" : "DATE" },
      // "TRAFİK SİGORTA BİTİŞ TARİHİ" : {"type" : "DATE" },
      // "KASKO BAŞLANGIÇ TARİHİ" : {"type" : "DATE" },
      // "KASKO BİTİŞ TARİHİ" : {"type" : "DATE" },
      // "KASKO TUTARI" : {"type" : "TL_FORMAT" },
      // "TRAFİK SİGORTA TUTAR" : {"type" : "TL_FORMAT" },
      // "ARAÇ ALIŞ FİYATI" : {"type" : "TL_FORMAT" },
      // "ÖTV KDV HARİÇ TUTAR" : {"type" : "TL_FORMAT" },
      // "ÖTV TUTARI" : {"type" : "TL_FORMAT" },
      // "DİĞER FATURALI KDVSİZ TUTAR" : {"type" : "TL_FORMAT" },
      // "DİĞER FATURASIZ TUTAR" : {"type" : "TL_FORMAT" },
    }


    function checkRowAfterUpdate(rowObject){
      console.log(rowObject);
      rowObject["Fix Needed"] = "";
      headers.forEach((header, index) => {
        if(Object.keys(validateNeedColumns).includes(header)){
          if(header == "MARKA" || header == "MODEL" || header == "PAKET"){
              let result = { isValid : false , label : "" , id : ""};
              if(header == "MARKA"){
                result = validateStandardBMPID(rowObject[header],optionsBMP);
              }
              else if(header == "MODEL" ){
                if(rowObject["MARKA_RDS_ID"] == undefined || rowObject["MARKA_RDS_ID"] == null || rowObject["MARKA_RDS_ID"] == ""){
                    editFixNeed(rowObject,"Hatalı " + header);
                }
                else{
                  result = validateStandardBMPID(rowObject[header],optionsBMP.find((element) => element.id === rowObject["MARKA_RDS_ID"]).children);
                }
              }
              else if(header == "PAKET"){
                if(rowObject["MODEL_RDS_ID"] == undefined || rowObject["MODEL_RDS_ID"] == null || rowObject["MODEL_RDS_ID"] == ""){
                  editFixNeed(rowObject,"Hatalı " + header);
                }
                else{
                  result = validateStandardBMPID(rowObject[header],optionsBMP.find((element) => element.id === rowObject["MARKA_RDS_ID"]).children.find((element) => element.id === rowObject["MODEL_RDS_ID"]).children);
                }
              }
              if(!result.isValid){
                editFixNeed(rowObject,"Hatalı " + header);
              }else{
                rowObject[header] = result.id;
                rowObject[header + "_NAME"] = result.label;
                rowObject[header + "_RDS_ID"] = result.id;
              }
          }
          else if(validateNeedColumns[header].type === "LIST" && header != "MARKA" && header != "MODEL" && header != "PAKET")
          {
              let result = validateStandardWithID(rowObject[header],validateNeedColumns[header].list);
              if(!result.isValid){
                editFixNeed(rowObject,"Hatalı " + header);
              }else{
                rowObject[header] = result.id;
                rowObject[header + "_NAME"] = result.label;
                rowObject[header + "_RDS_ID"] = result.id;
              }
          }
          else if(validateNeedColumns[header].type === "STRING_REQUIRED"){
            if(row[index] == undefined || row[index] == null || row[index] == ""){
              editFixNeed(rowObject,"Hatalı " + header);
            }
          }

        }
  });

  console.log(rowObject);
      if(rowObject["Fix Needed"] == undefined || rowObject["Fix Needed"] == null || rowObject["Fix Needed"] == ""){
          rowObject["DUP"] = false;
          rowObject["DUP_CHECKED"] = false;
          console.log("Row Fixed");
          return true;
      }
      else
      {
        console.log("Row Not Fixed");
          return false;
      }
    }

     //#endregion

     //#region Utils

    const editFixNeed = (rowObject,text) => {
      if(rowObject['Fix Needed'] === undefined){
        rowObject['Fix Needed'] = text;
      }else{
        rowObject['Fix Needed'] += "," +text;
      }

    }

    //#endregion

    //#region Custom Cells
    const CustomCellView = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
          <View style={{  justifyContent : 'center'  }}>
              <Text style={{textAlign: 'left'}}>{rowData[dataKey]}</Text>
          </View>
        </Table.Cell>
    );


    function getCONCAT(type,rowData){
       switch(type){
          case "TAAHHUT_DATA":
            return rowData["TAAHHUT DURUMU"] == "YOK" || rowData["TAAHHUT DURUMU"] == undefined ? "" : rowData["TAAHHUT AYI_NAME"] + " " + rowData["VE/VEYA"] + " " + rowData["TAAHHUT KM_NAME"];
       }
      return "";
    }

    const CONCATCellView = ({ rowData, dataKey, ...props }) => (
      <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
        <View style={{  justifyContent : 'center'  }}>
            <Text style={{textAlign: 'left'}}>{getCONCAT(dataKey,rowData)}</Text>
        </View>
      </Table.Cell>
  );

    const BoolCell = ({ rowData, dataKey, ...props }) => (
      <Table.Cell {...props} style={{ padding: 7 , height: '100%' }}>
       { rowData["DUP_CHECKED"] ?
          (
            <View>
              {rowData["DUP"] ?
                (
                  <View style={{  justifyContent : 'center',backgroundColor: 'red', width : '100%' , height : '150%', borderRadius: 12}}>
                    <Text style={{textAlign: 'center', color: 'white', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}> Tekrar </Text>
                    </View>
                )
                :
                (
                  <View style={{  justifyContent : 'center',backgroundColor: 'green', width : '100%' , height : '150%', borderRadius: 12}}>
                    <Text style={{textAlign: 'center', color: 'white', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}> Yüklendi </Text>
                    </View>
                )
              }
            </View>
          )
          :
         (
            <View style={{  justifyContent : 'center',backgroundColor: 'white', width : '100%' , height : '100%', borderRadius: 12}}>
              <Text style={{textAlign: 'center', color: 'black', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}> - </Text>

            </View>
          )
        }
      </Table.Cell>
    );

    const ActionCellEdit = ({ rowData, dataKey, ...props }) => (
      <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
        <View style={{ height: '80%'  }}>
        <IconButton icon={<Icon><FontAwesomeIcon icon={"pen"} /></Icon>}
            onClick={
            () => {
              console.log(rowData);
              clickEditCar(rowData);
            }
          }></IconButton>

        </View>
      </Table.Cell>
    );

    //#endregion

    function clickEditCar (row)  {
      console.log(row);
      setEditRow(row);
      setIsEditPopupOpen(true);
    }

    function updateRowOnData(inputData){
        setIsEditPopupOpen(false);
        let temps = []
        wrongRows.forEach((row) => {
          if(row["ID"] != inputData["ID"]){
              temps.push(row);
              console.log(row)
          }
        });
        if(!checkRowAfterUpdate(inputData)){
          temps.push(inputData);
          console.log(temps);
        }
        else{
          let dataFinal = [inputData,...correctRows]
          console.log(dataFinal);
          setcorrectRows(dataFinal);
        }
        setWrongRows(temps);

    }

    function convertToJSDate(str) {
      const [day, month, year] = str.split('/');
      console.log(`${year}-${month}-${day}`);
      return new Date(`${year}-${month}-${day}`);
    }

    function getCalculatedPrices(OTV_KDV_EXC, OTV_PRICE, KDV_RATE){
      let basePrice = Number(OTV_KDV_EXC) + Number(OTV_PRICE);
      let kdvPrice = basePrice * Number(KDV_RATE / 100);
      return {"KDV_TUTARI" :  kdvPrice , "OTV_KDV_TUTARI" : basePrice + kdvPrice};
    }

    const [isLoaded,setIsLoaded] = useState(false);
    const [ButtonPressed,setButtonPressed] = useState(false);
    useEffect(() => {  if(isLoaded) {  setButtonPressed(false);}},[isLoaded])



    const  sendData = async ()  =>{
      const exportData = [];
      setButtonPressed(true);
      setIsLoaded(false);
      correctRows.forEach((row) => {
        const carData = {
          "TESCİL_TARİHİ": row["TESCİL TARİHİ"] == undefined ? null : new Date(row["TESCİL TARİHİ"]),
          "MARKA": row["MARKA_RDS_ID"] == undefined ? null : row["MARKA_RDS_ID"],
          "MODEL": row["MODEL_RDS_ID"] == undefined ? null : row["MODEL_RDS_ID"],
          "PAKET":  row["PAKET_RDS_ID"] == undefined ? null : row["PAKET_RDS_ID"],
          "DONANIM_EKSTRA" : row["EKSTRA DONANIM_RDS_ID"] == undefined ? null : row["EKSTRA DONANIM_RDS_ID"],
          "PLAKA":  row["PLAKA"] == undefined ? null : row["PLAKA"],
          "KASA_TİPİ" : row["KASA_RDS_ID"] == undefined ? null : row["KASA_RDS_ID"],
          "BAKIM_KM_PERİYODU" : row["BAKIM KM PERİYODU_RDS_ID"] == undefined ? null : row["BAKIM KM PERİYODU_RDS_ID"],
          "VİTES_TİPİ" : row["VİTES_RDS_ID"] == undefined ? null : row["VİTES_RDS_ID"],
          "YAKIT_TİPİ" : row["YAKIT TİPİ_RDS_ID"] == undefined ? null : row["YAKIT TİPİ_RDS_ID"],
          "CC" : row["CC_RDS_ID"] == undefined ? null : row["CC_RDS_ID"],
          "MODEL_YILI" : row["MODEL YILI_RDS_ID"] == undefined ? null : row["MODEL YILI_RDS_ID"],
          "HP" : row["HP"] == undefined ? null : row["HP"],
          "ŞASİ_NO":  row["ŞASİ"] == undefined ? null : row["ŞASİ"],
          "MOTOR_NO":  row["MOTOR NO"] == undefined ? null : row["MOTOR NO"],
          "RENK":  row["RENK_NAME"] == undefined ? null : row["RENK_NAME"],
          "RUHSAT_SERİ_NO": row["TESCİL BELGE NO"] == undefined ? null : row["TESCİL BELGE NO"],
          "ARAÇ_SAHİBİ" : row["RUHSAT SAHİBİ_RDS_ID"] == undefined ? null : row["RUHSAT SAHİBİ_RDS_ID"],
          "İLK_TESCİL_TARİHİ": row["İLK TESCİL TARİHİ"] == undefined ? null : new Date(row["İLK TESCİL TARİHİ"]),
          "ARAC_KM": row["ARAÇ KM"] == undefined ? null : row["ARAÇ KM"],
          "CC_SINIF": row["CC SINIFI"] == undefined ? null : row["CC SINIFI"].toString(),
          "KW": row["KW"] == undefined ? null : row["KW"],
          "RENK_ID": row["RENK_RDS_ID"] == undefined ? null : row["RENK_RDS_ID"],
          "MUAYENE_BİTİŞ_TARİHİ": row["MUAYENE TARİHİ"] == undefined ? null : new Date(row["MUAYENE TARİHİ"]),
          "ID" : row["ID"],
          "RADIO_CODE": row["RADYO KODU"] == undefined ? "" : row["RADYO KODU"],
          "MARKA_KODU": row["MARKA KODU"] == undefined ? null : row["MARKA KODU"],
          "TIP_KODU": row["TİP KODU"] == undefined ? null : row["TİP KODU"],
          "ARAÇ_DURUM" : row["ARAÇ DURUM_RDS_ID"] == undefined ? null : row["ARAÇ DURUM_RDS_ID"],
          "CAR_BUY_TYPE" : row["ARAÇ EDİNİM ŞEKLİ_RDS_ID"] == undefined ? null : row["ARAÇ EDİNİM ŞEKLİ_RDS_ID"],
          "BUY_DEPARTMENT" : row["ARAÇ ALIŞ DEPARTMANI_RDS_ID"] == undefined ? null : row["ARAÇ ALIŞ DEPARTMANI_RDS_ID"],
          "TAAHHUT_VAR" : row["TAAHHUT DURUMU_RDS_ID"] == undefined ? null : row["TAAHHUT DURUMU_RDS_ID"],
          "TAAHHUT_DATA" : row["TAAHHUT DURUMU_RDS_ID"] == undefined ||  row["TAAHHUT DURUMU_RDS_ID"] == 0 ? null :    {
            "TAAHHUT_MONTH" : row["TAAHHUT AYI_RDS_ID"] == undefined ? null : row["TAAHHUT AYI_RDS_ID"],
            "TAAHHUT_KM" : row["TAAHHUT KM_RDS_ID"] == undefined ? null : row["TAAHHUT KM_RDS_ID"],
            "TAAHHUT_LOGIC" : row["VE/VEYA_RDS_ID"] == undefined ? null : row["VE/VEYA_RDS_ID"],
            "TAAHHUT_NOTES" : row["TAAHHUT NOTLARI"] == undefined ? null : row["TAAHHUT NOTLARI"],
          },
          "EXCEL_ID" : row["ID"],
          "BUY_INFO_COND": row["ALIŞ BİLGİSİ DURUMU_RDS_ID"] == undefined ? 0 : row["ALIŞ BİLGİSİ DURUMU_RDS_ID"],
          "SIFIR2" : row["ARAÇ EDİNİM ŞEKLİ_NAME"].includes("2") ? "2": "SIFIR" ,
          "BUY_INFO":
          {
            "BUY_TYPE" : row["SATIN ALIM ŞEKLİ_RDS_ID"] == undefined ? null : row["SATIN ALIM ŞEKLİ_RDS_ID"],
            "CLIENT_ID" : row["TEDARİKÇİ_RDS_ID"] == undefined ? null : row["TEDARİKÇİ_RDS_ID"],
            "FATURA_DATE" : row["ALIŞ TARİHİ"] == undefined ? null : new Date(row["ALIŞ TARİHİ"]),
            "OTV" : row["OTV"] == undefined ? null : row["OTV"],
            "OTV_TUTARI" :  row["ÖTV TUTARI"] == undefined ? null : row["ÖTV TUTARI"],
            "KDV" : row["KDV"] == undefined ? null : row["KDV"],
            "KDV_TUTARI" : getCalculatedPrices().KDV_TUTARI,
            "OTV_KDV_HARIC_TUTAR" : row["ÖTV KDV HARİÇ TUTAR"] == undefined ? null : row["ÖTV KDV HARİÇ TUTAR"],
            "OTV_KDV_DAHIL_TUTAR" : row["ÖTV KDV DAHİL TUTAR"] == undefined ? getCalculatedPrices().OTV_KDV_TUTARI : row["ÖTV KDV DAHİL TUTAR"],
            "DIGER_KDV" : row["DİĞER KDV"] == undefined ? null : row["DİĞER KDV"],
            "DIGER_KDVSIZ_TUTAR" : row["DİĞER FATURALI KDVSİZ TUTAR"] == undefined ? null : row["DİĞER FATURALI KDVSİZ TUTAR"],
            "DIGER_KDV_TUTARI" : row["DİĞER FATURALI KDV TUTARI"] == undefined ? null : row["DİĞER FATURALI KDV TUTARI"],
            "DIGER_FATURASIZ_TUTAR" : row["DİĞER FATURASIZ TUTAR"] == undefined ? null : row["DİĞER FATURASIZ TUTAR"],
            "BUY_DETAILED_CATEGORY" : row["SATIN ALIM KANALI_RDS_ID"] == undefined ? null : row["SATIN ALIM KANALI_RDS_ID"],
            "NOTES" : row["ALIŞ NOTLARI"] == undefined ? null : row["ALIŞ NOTLARI"],
          },
          "KASKO TİPİ" : row["KASKO TİPİ_RDS_ID"] == undefined ? null : row["KASKO TİPİ_RDS_ID"],
          "KASKO_INFO" :
          {
            "category" : "KASKO",
            "DOCUMENT_NO" : row["KASKO POLİÇE NUMARASI"] == undefined ? null : row["KASKO POLİÇE NUMARASI"],
            "START_DATE": row["KASKO BAŞLANGIÇ TARİHİ"] == undefined ? null : new Date(row["KASKO BAŞLANGIÇ TARİHİ"]),
            "END_DATE" : row["KASKO BİTİŞ TARİHİ"] == undefined ? null : new Date(row["KASKO BİTİŞ TARİHİ"]),
            "AMOUNT" : row["KASKO TUTAR"] == undefined ? null : row["KASKO TUTAR"],
            "INSURENCE_FIRM_ID": row["KASKO FİRMASI_RDS_ID"] == undefined ? null : row["KASKO FİRMASI_RDS_ID"],
            "AGENT_FIRM_ID" : row["KASKO ACENTE_RDS_ID"] == undefined ? null : row["KASKO ACENTE_RDS_ID"],
            "EMERGENCY_HELP_NO": row["KASKO YARDIM HATTI"] == undefined ? "" : row["KASKO YARDIM HATTI"],
            "NOTES" : row["KASKO NOT"] == undefined ? "" : row["KASKO NOT"],
            "IS_ACTIVE" :  "1",
            "S3_KEY" : "",
          },
          "TRAFİK SİGORTA DURUMU": row["TRAFİK SİGORTA DURUMU_RDS_ID"] == undefined ? null : row["TRAFİK SİGORTA DURUMU_RDS_ID"],
          "TRAFFIC_INFO" :
          {
            "category" : "TRAFFIC",
            "DOCUMENT_NO" : row["TRAFİK SİGORTA POLİÇE NUMARASI"] == undefined ? null : row["TRAFİK SİGORTA POLİÇE NUMARASI"],
            "START_DATE": row["TRAFİK SİGORTA BAŞLANGIÇ TARİHİ"] == undefined ? null : new Date(row["TRAFİK SİGORTA BAŞLANGIÇ TARİHİ"]),
            "END_DATE" : row["TRAFİK SİGORTA BİTİŞ TARİHİ"] == undefined ? null : new Date(row["TRAFİK SİGORTA BİTİŞ TARİHİ"]),
            "AMOUNT" : row["TRAFİK SİGORTA TUTAR"] == undefined ? null : row["TRAFİK SİGORTA TUTAR"],
            "INSURENCE_FIRM_ID": row["TRAFİK SİGORTA FİRMASI_RDS_ID"] == undefined ? null : row["TRAFİK SİGORTA FİRMASI_RDS_ID"],
            "AGENT_FIRM_ID" : row["TRAFİK SİGORTA ACENTE_RDS_ID"] == undefined ? null : row["TRAFİK SİGORTA ACENTE_RDS_ID"],
            "EMERGENCY_HELP_NO": row["TRAFİK YARDIM HATTI"] == undefined ? "" : row["TRAFİK YARDIM HATTI"],
            "NOTES" : row["TRAFİK SİGORTA NOT"] == undefined ? "" : row["TRAFİK SİGORTA NOT"],
            "IS_ACTIVE" :  "1",
            "S3_KEY" : "",
          }
        }
        exportData.push(carData);

      });
      console.log(sessionStorage.getItem("idToken"));
      const api = configdata.API_BASE + '/cardata'
      const idToken = await fetchIdToken();
      const headers = {
        "Authorization" : `Bearer ${idToken}`,
    }
      const mainBody ={
        "ENV" :configdata.APP_ENV ,
        "type": "MULTIPLE_CAR_UPLOAD" ,
        "data": exportData,
        "ARAÇ_DURUM" : importType == "FRESH" ? 1 : 99
      }

      console.log(mainBody);
      axios.post(api,mainBody,{headers} ).then((response) => {
        console.log(response);
        if(response.data["errorMessage"] != null){
          messageToggle("Araçlar eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
        }else{
          if(response.data.length > 0 && response.data != "SUCCESS"){
            setHasDup(true);
            setIsLoaded(true);
            let responseArray =  [];
            correctRows.forEach((row) => {
              let rowTemp = {...row};
              if(response.data.find((item) => item["PLAKA"] === row["PLAKA"]) !== undefined)
              {
                  rowTemp["DUP"]  = true;
                  rowTemp["DUP_CHECKED"] = true;

              }else{
                rowTemp["DUP"]  = false;
                rowTemp["DUP_CHECKED"] = true;
              }
              responseArray.push(rowTemp);

          });
          setcorrectRows(responseArray);
          console.log(responseArray);
          console.log(response.data);

        }
        if(importType == "STANDARD"){
           messageToggle("Araçlar Başarıyla Eklendi", "success");
        }
      }})
      .catch((error) => {
        console.log(error);
        messageToggle("Araçlar eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
      });
    }
    //#region FRESH CAR ADD FUNCTIONS
    const [carData,setCarData] = useState([
      {
        "MARKA_NAME": "",
        "MODEL_NAME": "",
        "PAKET_NAME": "",
        "KASA_TİPİ_NAME" : "",
        "VİTES_TİPİ_NAME" : "",
        "YAKIT_TİPİ_NAME" : "",
        "RENKID_NAME": "",
        "MARKA": 0,
        "MODEL": 0,
        "PAKET": 0,
        "KASA_TİPİ" : 0,
        "VİTES_TİPİ" : 0,
        "YAKIT_TİPİ" : 0,
        "CC" : 0,
        "MODEL_YILI" : 2022,
        "HP" : 0,
        "KW" : 0,
        "RENK": "",
        "RENK_ID": 0,
        "ARAÇ_DURUM" : 0,
        "ID": 0,
        "Count": 1,
        "SALES_CODE" : "",
        "SALES_CODE_NAME" : 0,
        "şasiNos" : "",
        "ŞASİ_NO" : "",
        "PLAKA": "",
        "ARAC_KM": 0,
        "CC_SINIF": 0,
        "MOTOR_NO": "",
        "TESCİL_TARİHİ": null,
        "İLK_TESCİL_TARİHİ": null,
        "BAKIM_KM_PERİYODU": 0,
        "HGS_ETİKET_NO": "",
        "TRAFIK_POLİÇE_NO": "",
        "TRAFIK_POLICE_BİTİŞ_TARİHİ": null,
        "MUAYENE_BİTİŞ_TARİHİ": null,
        "RUHSAT_SERİ_NO": "",
        "RADIO_CODE": "",
        "KASKO_NO": "",
        "KASKO_BİTİŞ_TARİHİ": null,
        "ARAÇ_SAHİBİ": 0,
        "KULLANICI": 1
      }
    ]);


    async function sendListCarData(){
      console.log(carData);
      const idToken = await fetchIdToken();
      const api = configdata.API_BASE + '/cardata'
      const headers = {
        "Authorization" : `Bearer ${idToken}`,
      }

      let final = populateCarData();

      console.log(final);

      carData.forEach((item) => {
        item["ARAÇ_DURUM"] = 0;
      });
        const mainBody ={
          "ENV" :configdata.APP_ENV ,
          "type": "MULTIPLE_CAR_UPLOAD" ,
          "data": final,
          "ARAÇ_DURUM" : 1,
        }

        axios.post(api,mainBody,{headers}).then((response) => {
          console.log(response);
          if(response.status == 200 ){
            alert("İşlem Başarılı");
          }else{
            alert("İşlem Başarısız");
          }
        }).catch((error) => {
          console.log(error);
        })
    }

    function populateCarData(){
      let final = [];
      const now = new Date();
      const day = now.getDate();
      const month = now.getMonth() + 1; // add 1 because months are 0-indexed
      const year = now.getFullYear();

      const dateString = `${day}/${month}/${year}`;

      carData.forEach((item) => {

        for(let i = 0; i < item["Count"]; i++){
          let temp = {...item};

          temp["şasiNos"] = [];
          temp["ŞASİ_NO"] = item["şasiNos"][i];
          temp["ARAÇ_DURUM"] = 1;
          temp["SPEC_CODE"] = item["SALES_CODE"] + "_" + item["MARKA_NAME"] + "_" + item["MODEL_NAME"] + "_" + item["PAKET_NAME"] + "_" + item["CC"] +"_"+ item["HP"] + "_" + dateString + "_" + i;
          console.log(temp["SPEC_CODE"]);
          final.push(temp);
        }
      }
      );

      return final;
    }

    function addNewItemToList(){
      let tempItem = {...carData[carData.length -1]};
      console.log("Car Data List ------------------------------------------------------------------");
      console.log(carData);
      console.log("ADD NEW ITEM TO LIST -----------------------------------------------------------");
      console.log(tempItem); // Making a shallow copy of tempCarData if it's an object
      let lastID = carData.length > 0 ? carData[carData.length - 1]["ID"] : 0;
      tempItem["ID"] = lastID + 1;
      const newCarData = [...carData, tempItem]; // Creating a new array

      setCarData(newCarData);
    }

    function updateCarDataWithID(inputData){
      let temp = [];
      carData.forEach((item) => {
        if(item["ID"] == inputData["ID"]){
          temp.push(inputData);
        }else{
          temp.push(item);
        }
      });
      setCarData(temp);
      console.log("UPDATE CAR DATA WITH ID --------------------------------------------------------");
      console.log(temp);
    }

    function addMultipleCarData(inputData,addCount){
      let temp = [];
      let lastID = carData.length > 0 ? carData[carData.length - 1]["ID"] : 0;
      let tempItem = {...inputData};
      for(let i = 0 ; i < addCount ; i++){
        tempItem["ID"] = lastID + 1 + i;
        temp.push(tempItem);
      }
      console.log("ADD MULTIPLE CAR DATA ----------------------------------------------------------");
      console.log(temp);
      const newCarData = [...carData, ...temp]; // Creating a new array
      setCarData(newCarData);
    }

    function deleteCarDataWithID(inputData){
      let temp = [];
      carData.forEach((item) => {
        if(item["ID"] != inputData["ID"]){
          temp.push(item);
        }
      });
      setCarData(temp);
    }

    function setCarDataCount(inputData,count){
      let temp = [];
      carData.forEach((item) => {
        if(item["ID"] == inputData["ID"]){
          item["Count"] = count;
          temp.push(item);
        }else{
          temp.push(item);
        }
      });
      setCarData(temp);
    }

    //#endregion


    return (
      <View style={{alignItems: 'center',backgroundColor: '#FFF',minHeight : '100%',paddingTop : 40}}>
        <Drawer open = {isEditPopupOpen} onClose={() => setIsEditPopupOpen(false)} overflow={true} size={"full"} backdrop={'static'}>
          <View> <EditCarScreen setRow={updateRowOnData} data = {editRow} type = "UPDATE_ON_ROW"></EditCarScreen>  </View>
        </Drawer>
        <View style={[{backgroundColor: 'white', borderRadius: 5, width: '95%', height: 100, flexDirection: 'row',marginVertical : 5, alignItems: 'center', justifyContent :'space-evenly'},mainStyles.shadow]}>
          <View style={{alignItems:'center',marginHorizontal: 10}}>
            <Text style={mainStyles.textOnDropDown}> İçe Aktarılacak Araç Tipi </Text>
            <SelectPicker style={{width: 250}} data = {importOptions} value={importType} onChange={(value) => {setImportType(value);}}></SelectPicker>
          </View>
          <FileUploader handleChange={handleFileUpload} name="file" types={["XLSX","XLS"]} />
         <CreateExcelTemplate></CreateExcelTemplate>
        </View>

        { importType != 'FRESH' ?
                (<View style = {{alignItems:'center', width: '100%'}}>
                  <View style={[{alignItems : 'center' ,marginVertical: 20, width: '95%',backgroundColor: 'white', borderRadius: 12,padding: 15},mainStyles.shadow]}>
                    <View style={{alignItems : 'center' ,marginVertical : 12}}>
                    </View>
                    <View style={{width: '100%' , justifyContent : 'space-between', padding: 20, flexDirection: 'row'}}>
                        <View style={{width: 100}}> </View>
                        <Text style={mainStyles.textStandard}> Hatalı Veri Bulunan Araçlar</Text>
                        <ExportData data ={wrongRows} ></ExportData>
                    </View>
                    <View style={{width: '95%', height: wrongRows.length > 2 ? 600: 150}}>
                      <Table data={wrongRows}height={wrongRows.length > 2 ? 600: 150} bordered cellBordered >
                          <Table.Column resizable sortable>
                            <Table.HeaderCell>Düzenle </Table.HeaderCell>
                            <ActionCellEdit dataKey="Action" />
                          </Table.Column>
                          <Table.Column width={300} resizable >
                            <Table.HeaderCell>Düzeltme Gerekiyor</Table.HeaderCell>
                            <Table.Cell dataKey="Fix Needed" />
                          </Table.Column>
                        {columns.map((colName) => (
                          <Table.Column dataKey={colName.key} key={colName.key} resizable sortable>
                            <Table.HeaderCell>{colName.name}</Table.HeaderCell>
                            <CustomCellView dataKey={colName.key} />
                          </Table.Column>
                        ))}
                      </Table>
                    </View>
                  </View>
                  <View style={[{alignItems : 'center' ,marginVertical: 20, width: '95%',backgroundColor: 'white', borderRadius: 12,padding: 15},mainStyles.shadow]}>
                    <View style={{width: '100%' , justifyContent : 'space-between', padding: 20 , flexDirection: 'row'}}>
                        <View style={{width: 100}}> </View>
                        <Text style={mainStyles.textStandard}> Yüklenebilir Araçlar</Text>
                        <ExportData data ={correctRows} ></ExportData>
                    </View>
                    <View style={{width: '95%', height: correctRows.length > 2 ? 600: 150}}>
                        <Table data={correctRows} height={correctRows.length > 2 ? 600: 150} bordered cellBordered>
                          {
                            hasDup &&
                          <Table.Column key={"DUP"} resizable sortable>
                            <Table.HeaderCell>TEKRAR </Table.HeaderCell>
                            <BoolCell dataKey={"DUP"} />
                          </Table.Column>
                          }

                          {columns.map((colName) => (

                            colName.key == "TAAHHUT_DATA" ?
                            (
                              <Table.Column key={colName.key} dataKey={colName.key} resizable sortable>
                                <Table.HeaderCell>{colName.name}</Table.HeaderCell>
                                <CONCATCellView />
                              </Table.Column>
                            )
                            :
                            (
                              <Table.Column key={colName.key} dataKey={colName.key} resizable sortable>
                                <Table.HeaderCell>{colName.name}</Table.HeaderCell>
                                <CustomCellView dataKey={colName.key} />
                              </Table.Column>
                            )
                          ))}

                        </Table>
                    </View>
                    <View style={{ flexDirection: 'row', width: '100%',alignItems: 'center',justifyContent: 'center',marginBottom: 10,padding: 20}}>
                          {/* <ExportData data ={correctRows} ></ExportData> */}
                          <IconButton disabled={ButtonPressed} style={{width: 200}} onClick={sendData} icon={<Icon><FontAwesomeIcon icon = {'upload'}></FontAwesomeIcon></Icon>} > UPLOAD</IconButton>
                          {
                            (!isLoaded && ButtonPressed )&&
                            <View style={{padding: 3, marginHorizontal: 5}}>
                              {/* <Text style={{marginHorizontal: 10, color: 'white' , fontWeight: 'bold' }}> </Text> */}
                              <Loader></Loader>
                            </View>
                          }
                    </View>
                  </View>
                </View>)
            : importType == 'FRESH' &&
            (
              <View style = {{marginVertical: 24,flex:1, width: '95%' , backgroundColor : 'white' , borderRadius: 5}}>
                <ScrollView horizontal persistentScrollbar contentContainerStyle = {{flexGrow: 1}}>
                  <List>
                      <List.Item>

                        <View style = {{flexDirection: 'row' ,width: '100%',height: 40, alignItems: 'center', justifyContent: 'flex-start'}}>
                            <View style={{width: 10}}></View>
                            <IconButton style={{marginHorizontal: 5, width: 120}} onClick={addNewItemToList} icon={<Icon> <FontAwesomeIcon icon={"plus"}> </FontAwesomeIcon></Icon>} > Yeni Ekle </IconButton>
                            <View style={{width: 10}}></View>
                            <IconButton style={{marginHorizontal: 5,width: 120, backgroundColor: 'green', color: 'white'}} onClick={sendListCarData} icon={<Icon style={{backgroundColor: 'green'}}> <FontAwesomeIcon icon={'upload'}> </FontAwesomeIcon></Icon>} > Kaydet </IconButton>
                            {/* <IconButton style={{marginVertical: 5,width: 120, backgroundColor: '#f3edff', color: 'black'}} onClick={sendListCarData} icon={<Icon style={{backgroundColor:'#f3edff' }}> <FontAwesomeIcon icon={faDownload}> </FontAwesomeIcon></Icon>} > Export </IconButton> */}
                        </View>
                      </List.Item>
                      <List.Item>
                        <HeadersComp></HeadersComp>
                      </List.Item>

                    {
                      carData.map((row) => (
                        <List.Item key={row.ID}>
                          <ListCarDataItem setCount={setCarDataCount} delete={deleteCarDataWithID} addMulti={addMultipleCarData} setRow = {updateCarDataWithID} data = {row} ></ListCarDataItem>
                        </List.Item>
                      ))}
                        <List.Item>

                        </List.Item>
                  </List>

                </ScrollView>
              </View>
            )
        }
      </View>
    );
  }




const ListCarDataItem = (props) => {
  const styles = {
    buttonStyleRed:{
      textAlign: 'center', backgroundColor : '#d9534f',color: 'white', borderRadius: 7,width: 30, height: 35
    },
    iconStyleRed:{
      textAlign: 'center', backgroundColor : '#d9534f',color: 'white'
    },
    iconStyleGreen:{
      textAlign: 'center', backgroundColor : '#5cb85c',color: 'white'
    },
    buttonStyleGreen:{
      textAlign: 'center', backgroundColor : '#5cb85c',color: 'white', borderRadius: 7,width: 30 , height: 35
    },
    buttonStyleNone:{
      width: 0
    },
  }

  const [addCount, setAddCount] = useState(1);

  useEffect(() => {
    props.setCount(props.data,addCount);
    newCarRef.current.updateŞasiNos();
  }, [addCount]);

    const newCarRef = useRef(null);

  // function multiplieData(data, count){
  //   let temp = [];
  //   for(let i = 0; i < count; i++){
  //     temp.push(data);
  //   }
  //   return temp;
  // }

  // function getHeaders(input){
  //   let temp = [];
  //   for(let i = 0; i < input.length; i++){
  //     if(input[i].includes("_NAME")){
  //       temp.push(input[i]);
  //     }
  //   }
  //   return temp;
  // }

  return(
    <View>
      <View style = {{marginHorizontal :10 ,flex:1, flexDirection: 'row' , backgroundColor : 'white' }}>
        <View style={{flexDirection: 'row'}}>
        <IconButton
          style={styles.buttonStyleRed}
          icon = {<Icon style={styles.iconStyleRed}><FontAwesomeIcon icon={'minus'}> </FontAwesomeIcon></Icon>}
          onClick={() => {props.delete(props.data)}}
        />
        <View style={{width: 5}}></View>
        {/* <IconButton
          style={styles.buttonStyleGreen}
          icon = {<Icon style={styles.iconStyleGreen}><FontAwesomeIcon icon={faLayerGroup}> </FontAwesomeIcon></Icon>}
          onClick={() => {props.addMulti(props.data, addCount)}}
        /> */}
        <View style={{width: 5}}></View>
        <MaskedInput  style={{width: 30,height: 35, padding: 5, alignItems: 'center', justifyContent: 'center'}} guide= {false} mask={[/\d/,/\d/]} value={addCount}  onChange={ (value) => {setAddCount(value)}} name= {"Add Count"} />
        </View>
        <NewCarLabel ref= {newCarRef} setRow = {props.setRow} data = {props.data} ></NewCarLabel>
      </View>
      {/* <View style = {{marginVertical: 10}}>
        <Table
        autoHeight
        data = {multiplieData(props.data, addCount)}
        cellBordered
        bordered
        >
          { getHeaders(Object.keys(props.data)).map((key) => (
            <Table.Column width={120} key={key} >
              <Table.HeaderCell>{key.split("_NAME")[0]}</Table.HeaderCell>
              <Table.Cell dataKey={key} />
              </Table.Column>
          ))}
          </Table>
        </View> */}
    </View>


  );

}

const HeadersComp = () => {

  const item_style = {
    header: {
      fontWeight: 'bold',
      width: 200,
      marginHorizontal: 15,

    }
  }
  return(
    <View style= {{flexGrow:1,flexDirection: 'row', justifyContent: 'flex-start',paddingHorizontal:20,marginLeft: 20}}>
  <Text style={{fontWeight: 'bold'}}> Adet </Text>
    <View style ={{flexDirection: 'row',marginLeft: 120}} >
      <Text style={item_style.header}> Satın Alım Yeri *</Text>
      <Text style={item_style.header}> Marka *</Text>
      <Text style={item_style.header}> Model *</Text>
      <Text style={item_style.header}> Paket *</Text>
      <Text style={item_style.header}> Model Yılı *</Text>
      <Text style={item_style.header}> Vites Tipi *</Text>
      <Text style={item_style.header}> Kasa Tipi  *</Text>
      <Text style={item_style.header}> CC  *</Text>
      <Text style={item_style.header}> HP  *</Text>
      <Text style={item_style.header}> KW  *</Text>
      <Text style={item_style.header}> Yakıt Tipi  *</Text>
      <Text style={item_style.header}> Renk *</Text>
      <Text style={item_style.header}> Marka Renk </Text>
    </View>
  </View>
  )
}

export default MultipleCarScreen;

const mainStyles = StyleSheet.create({
  textStandard : {marginVertical: 18,fontSize: 18,color: '#333333',fontWeight: '500'},
  textOnDropDown : {fontSize: 14,fontWeight: '500', color: '#333333',marginVertical: 10},
  shadow: {
    shadowColor: '#BDC9FF',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity:  0.2,
    shadowRadius: 12,
    elevation: 5}
});
