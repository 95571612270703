import { Text, View , StyleSheet, TouchableOpacity,TextInput } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import { useToaster, Message , Input, IconButton} from 'rsuite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';

const PasswordInputText = (props) => {
    const [passwordVisiblity ,setPasswordVisibility] = useState(true);
    return(
      <View style={{width: '100%'}}>
        <View style = {{flexDirection: 'row' , width: '100%',padding: 5}}>
        <Input

          placeholder= {props.placeholder}
          onChange={text => {props.setText(text); props.checkError();}}
          type = {passwordVisiblity ? "password" : "text"}
        />
        <View style={{width: 10}}></View>
        <IconButton
          style={{width: 40, height:40}}
          onClick = {()=>setPasswordVisibility(!passwordVisiblity)}
          icon={<Icon> <FontAwesomeIcon icon = { passwordVisiblity ?  'eye' : 'eye-slash'} ></FontAwesomeIcon> </Icon>}
        >
        </IconButton>
      </View>
          {/* <MaterialCommunityIcons size={20} name = {passwordVisiblity ? "eye" : "eye-off"}></MaterialCommunityIcons> */}

        {props.showError && <View style={{flexDirection: 'row', justifyContent: 'flex-start' , alignItems: 'center'}}>
                <MaterialCommunityIcons name = "alert" color={"#F26938"} />
                <Text style = {{marginHorizontal: 10}}>
                  {props.errorText}
                </Text>
          </View>}
      </View>

    )
  }
  const styles = StyleSheet.create({
    inputText: {
        borderWidth: 1,
        borderColor: 'black',
        height: 50,
        borderRadius: 12,
        padding: 3,
        marginVertical: 5 ,
        outlineStyle : 'none' },
  })
  export default PasswordInputText;

