import { Text, View , StyleSheet,FlatList, TouchableOpacity,TextInput,ScrollView,SafeAreaView,Image,ActivityIndicator, Dimensions } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import axios from 'axios';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import CarInfoScreen from './CarInfoScreen';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import NewCarScreen from './NewCarScreen';
import { Message , useToaster,Modal, Button, Popover , Form ,Nav,TreePicker ,SelectPicker , CheckPicker,Input,Pagination,IconButton, Table ,Checkbox, DateRangePicker, Container,RangeSlider,TagPicker, Slider, Whisper, Tree, Dropdown, Drawer, Stack, List, Panel} from 'rsuite';
const { Column, HeaderCell, Cell } = Table;
import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ExportData from '../../Components/TableManagement/ExportData';
import FileViewer from '../../Components/FileManagements/FileViewer';
import FileLoader from '../../Components/FileManagements/FileLoader';
import locale from '../../tr';
import { BrandSvg } from '../../Components/BrandIconManager';
import configdata from '../../config/config.json';


//#region filter imports
import DateRangeFilter from './/Filters/DateRangeFilter.js';
import SliderFilter from './Filters/SliderFilter';
import FreeTextFilter from './Filters/FreeTextFilter';
import CarTagFilter from './Filters/CarTagFilter';
import BMPFilter from './Filters/BMPFilter';
import TagPickerFilter from './Filters/TagPickerFilter';
import FilterCompChooser from './Filters/FilterCompChooser.';

//#endregion

//#region Modal imports
import CarLicenceAddModal from './Modals/CarLicenceAddModal';
import EditCarModal from './Modals/EditCarModal';
import ColumnManager from './Modals/ColumnChooserModal';
//#endregion

import {useMessage} from '../../hooks/useMessage';
import { useUserToken } from '../../hooks/useUserToken';
import usePagination from '../../hooks/usePagination';
import useTable from '../../hooks/useTable';
import NewRentAggreementDrawer from '../RentScreens/RentAgreement/NewRentAggreementDrawer';
import { useDropdownData } from '../../hooks/useDropdownData';
import CustomText from '../../Components/CustomText';
import CarSummaryCardPdf from './CarTabScreens/CarSummaryCardPdf';

import '../../../custom-theme.less';
import { LinearGradient } from 'react-native-svg';

//#region Tab Management
const Tab = createMaterialTopTabNavigator();

const TabNavigator = (props) => {

  function closeTab(id){
    console.log("close clicked");
    var TabListNew = [];

    props.tabList.forEach(element => {
      if(element.id != id){
        TabListNew.push(element);
      }
    });
    props.setArray(TabListNew);
    props.removeFromEnabled(id);
  }

  const CustomTabBar = ({ state, descriptors, navigation }) => {



    return (
      <View
        style={styles.bottomBar}>
        {state.routes.map((route, index) => {
          const isFocused = state.index === index;
          const { options } = descriptors[route.key];
          const borderShadowStyle = isFocused ? {
            shadowColor: '#000',
            shadowOffset: { width: 0, height: 0 }, // centered shadow
            shadowOpacity: 0.3,
            shadowRadius: 10,
            elevation: 5 ,
            borderBottomWidth: 2,
            borderColor: '#E4E3E3'
          } : {};

          const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;
          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
            })
            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name);
            }
          }


          return (
              <View style={[{
                backgroundColor: isFocused ? "#F8F9FA" : '#E4E3E3',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                flex: 1,
                borderLeftWidth: 1,
                borderRightWidth: 1,
                borderBottomWidth: 1,
                borderColor: '#ECEBEB',

              },borderShadowStyle]}
              >
                {options.id != "X0" &&
                    <TouchableOpacity
                      // style={{width: 20, height: 20 , backgroundColor: '#F26938', borderRadius: 100, marginHorizontal: 5}}
                      style={{marginHorizontal: 10}}
                        key={index}
                        onPress={()=> {closeTab(options.id);}}
                        accessibilityRole="button"
                        >
                        <View >
                          <Icon><FontAwesomeIcon icon={"times"} size={15} color={'#5A6273'} /></Icon>
                        </View>
                    </TouchableOpacity>
                  }

                  <TouchableOpacity
                      key={index}
                      onPress={onPress}
                      accessibilityRole="button"
                      style = {{marginHorizontal: 10, flex: 1, justifyContent: 'center', alignItems: 'center'}}
                      >
                    <View style={{padding: 5, flexDirection: 'row'}}>
                      {options.id != "X0" &&
                          <Image source={'/images/LOGO_' + options.brand + '.png'} style={{ width: 20, height: 20 , marginHorizontal: 5}}></Image>
                      }

                      <View style={{width: 5}}></View>
                        <CustomText fontSize= {16} >{label}</CustomText>
                        {/* <CustomText fontSize= {16} >{options.id}</CustomText> */}
                    </View>
                  </TouchableOpacity>


              </View>
          )
        })}
      </View>
    )
  }
  return (

      <Tab.Navigator tabBar={(props) => <CustomTabBar {...props} />}>
            {props.tabList.map((tab) => (
              <Tab.Screen
                key={tab.id}
                name={tab.name}
                component={tab.component}
                options={{
                  id: tab.id,
                  swipeEnabled: false,
                  brand: tab.brand
                }}
              />
          ))}
      </Tab.Navigator>
    );
}

//#endregion


const CarsManagementScreenWTabs = (props) => {


  const carsManagementRef= useRef();
  const CarsManagementWrapper = () => {
    return <CarsManagement givenData={props.givenData} sold = {props.sold != undefined ? true : false} ref={carsManagementRef} handleChange={handleChange} />;
  };

  const [TabList, setArray] = useState([
    {
      id: "X0",
      name: "Araç Tablosu",
      icon: "information-outline",
      component: CarsManagementWrapper,
    },
  ]);


  function handleChange (input){
    console.log("handle change");
    console.log(input);
    setArray((TabList) => [...TabList,input])
  }

  function removeFromEnabled (input){
    carsManagementRef.current.removeElement(input);
  }

  return(
    <TabNavigator removeFromEnabled={removeFromEnabled} setArray= {setArray} tabList = {TabList}></TabNavigator>
  )

}


const CarsManagement = forwardRef((props,ref) =>  {
  const { fetchIdToken } = useUserToken();

  const tableRef = useRef()
  const filterRef = useRef()
  const [data,setCarData] = useState([]);
  const [activate,closeIndictor] = useState(true);
  const [refresh,setRefresh] = useState(false);

  async function  getCars () {
    if(props.givenData != undefined){
      console.log("Given Data");
      console.log(props.givenData);
      setCarData(props.givenData);
      matchFilteredData(props.givenData);
      closeIndictor(false);
      return;
    }

    const idToken = await fetchIdToken();
    const headers = {
      "Authorization" : `Bearer ${idToken}`,
    }

      const body = {
      "type": props.sold ? "GET_SOLD_CARS" : "GET_CARS",
      "ENV" : configdata.APP_ENV
      }
      console.log(body);
        const api = configdata.API_BASE + '/cardata';
        axios.post(api,body,{headers}
          ).then(
            (response) => {
                console.log(response);
                console.log(response.data);
                let temp = [...response.data];
                temp.forEach(element => {
                  element["CHECKED"] = 0;
                  element["ARAÇ"] +=  element["DONANIM_EKSTRA"] == null ? " "  : " " + element["DONANIM_EKSTRA"];
                });
                console.log(temp);
                setCarData(temp);
                matchFilteredData(temp);
                closeIndictor(false);

            }
        ).catch(
            (error) => {
                console.log(error);
            }
        )
  }

  async function getDataFiltered(item){
    const idToken = await fetchIdToken();
    const headers = {
      "Authorization" : `Bearer ${idToken}`,
    }

      const body = {
      "type": props.sold ? "GET_SOLD_CARS_WITH_FILTER" : "GET_MULTIPLE_CAR_DATA_WITH_FILTER",
      "ENV" : configdata.APP_ENV,
      "QUERY_TYPE" : item.queryType,
      "QUERY_VALUE" : item.queryCheck

      }
      console.log(body);
        const api = configdata.API_BASE + '/cardata';
        axios.post(api,body,{headers}
          ).then(
            (response) => {
                console.log(response);
                console.log(response.data);
                let temp = [...response.data];
                temp.forEach(element => {
                  element["CHECKED"] = 0;
                  element["ARAÇ"] +=  element["DONANIM_EKSTRA"] == null ? " "  : " " + element["DONANIM_EKSTRA"];
                });
                console.log(temp);
                setCarData(temp);
                matchFilteredData(temp);
                closeIndictor(false);
                setRefresh(!refresh);
                tableRef.current.setPage1();
            }
        ).catch(
            (error) => {
                console.log(error);
            }
        )
  }

  function reset(){
    console.log("reset");
    getCars();
  }

  useEffect(()=> {
    if(data.length > 0){
      console.log("dataChanged");
      // tableRef.current.refresh();
    }
  },[data])

  // TABLE MANAGEMENT
  // all table headers ?? TODO -> can be taken from sql database
  const [tableHead,setTableHead] = useState([
        // "NO",
      // "KULLANICI" ,
      "PLAKA",
      "ARAÇ",
      "RUHSAT SAHİBİ" ,
      "ŞASİ" ,
      "ARAÇ DURUM",
      "MOTORNO" ,
      "MODEL YILI",
      "TESCİL TARİHİ",
      "TESCİL BELGE NO",
      "MUAYENE TARİHİ",
      "KALAN MUAYENE GÜNÜ",
      "MARKA" ,
      "MODEL",
      "KASA" ,
      "PAKET",
      "CC" ,
      "CC SINIFI",
      "YAKIT TİPİ",
      "VİTES",
      "HP",
      "RENK",
      "MARKA RENK",
      "ARAÇ KM",
      // "TAHMİNİ ARAC KM",
      "KASKO DEĞERİ",
      "BAKIM KM PERİYODU",
  ]);

  useImperativeHandle(ref,()=>({

      removeElement: (element) => {
          removeElementEnabledTabs(element);
      }
  }))


  const [choosenCar, setChoosenCar] = useState(undefined)
  const [CarChoosenRefresh,setCarChooseRefresh] = useState(false);
  // Tab Conttroller for adding new tab or navigating current
  const [isPopUpvisible,setPopUpVisible] = useState(false) // car popup for expandable car window option TO BE DEVELOPED -- ADD SCROLL for pdf viewer
  const navigation = useNavigation();

  const [enabledTabs,setEnabledTabs] = useState([]);
  function handleEnabledTabs (input){
    setEnabledTabs((enabledTabs) => [...enabledTabs,input])
  }

  function removeElementEnabledTabs(element){
      var index = enabledTabs.indexOf(element);
      if(index >= 0){
        enabledTabs.splice(index,1);
      }
      console.log(enabledTabs);

  }
  function chooseCar(input){
    setCarChooseRefresh(!CarChoosenRefresh);
    setChoosenCar(input);
  }

  const AddTab = (input) => {
    if(input  != undefined ){
      if(enabledTabs.includes(input.NO)){
        console.log("avaliable tab do not create new")
        navigation.navigate(input.PLAKA == '' ? input["ŞASİ"] : input.PLAKA);
      }else{
        console.log(choosenCar);
        props.handleChange(
          {
            id: input.NO,
            name: input.PLAKA == '' ? input["ŞASİ"] : input.PLAKA,
            icon: "car-side",
            brand: choosenCar["MARKA"],
            component: CarInfo
          })
        handleEnabledTabs(input.NO);
      }
    }
  }

  const  CarInfo = () => (<CarInfoScreen reset={() => {getCars(); setRefresh(!refresh)}} getCars = {getCars} data = {choosenCar}  toggleVisible = {setPopUpVisible} isVisible = {isPopUpvisible}></CarInfoScreen>)

  // table Button controllers

  useEffect(()=>{
    if(choosenCar != undefined ||choosenCar != null) {AddTab(choosenCar); console.log("car choose")} } ,[CarChoosenRefresh])


  useEffect(()=>{getCars(); },[])
  useEffect(()=>{
    console.log("get car refresh");
  },[refresh])

  // FILTER Operations
  const [filteredData,setFiltered] = useState([]);
  const [isFiltered, setApplied] = useState(false);

  function resetTableFilter(){
    setApplied(false);
    setFiltered(data);
    tableRef.current.refresh();
  }

  function filterApplied(input){
    setApplied(input)
    tableRef.current.setPage1();
  }

  function handleCheckChange(rowData,checked){

    let cardataTemp = [...data];
    cardataTemp.forEach(element => {
      if(element["NO"] == rowData["NO"]){
        element["CHECKED"] = checked ? 1 : 0;
      }
    }
    );
    setCarData(cardataTemp);
  }

  function checkAll(checked){
    let cardataTemp = [];
    console.log(data)
    console.log(filteredData)
    if(filterApplied && filteredData.length > 0 ){
    console.log("CHECK ALL Filtered")
        console.log(filteredData)
        filteredData.forEach(
          element => {
            let temp = {...element}
            temp["CHECKED"] = checked ? 1 : 0;
            cardataTemp.push(temp);
          }
        )
        console.log("Car data Temp")
        console.log(cardataTemp);
        setFiltered(cardataTemp);
    }else{
    console.log("CHECK ALL Original")
      console.log(data)
      data.forEach(
        element => {
          let temp = {...element}
          temp["CHECKED"] = checked ? 1 : 0;
          cardataTemp.push(temp);
        }
      )
      console.log("Car data Temp")
      console.log(cardataTemp);
      setCarData(cardataTemp);
    }

  }

  function checkedKeys(){
    let checkedKeys = [];
    data.forEach(element => {
      if(element["CHECKED"] == 1){
        checkedKeys.push(element["NO"]);
      }
    });
    return checkedKeys;
  }

  async function getFiles(){

    const  idToken = await fetchIdToken()
    const api = configdata.API_BASE + '/cardata';

    const headers = {
        "Authorization" : `Bearer ${idToken}`,
    }

    const body = {
        "type": "GET_S3_DATA_WITH_IDS",
        "ID_DATA": checkedKeys()
    }

    axios.post(api, body, { headers: headers}) // set responseType to text
    .then((response) => {
        console.log(Object.keys(response.data)); // Log the response data to inspect its structure

        const base64data = response.data.body; // Adjust this based on the logged structure

        const cleanedBase64 = base64data.replace(/\s+/g, '');
        const paddedBase64 = cleanedBase64.padEnd(cleanedBase64.length + (4 - cleanedBase64.length % 4) % 4, '=');

        const arrayBuffer = base64ToArrayBuffer(paddedBase64);
        const blob = new Blob([arrayBuffer], { type: response.headers['content-type'] || 'application/zip' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'files.zip';
        link.click();
    })
    .catch((error) => {
        console.log(error);
    });
  }

  function base64ToArrayBuffer(base64) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  function resetAfterUpdate(){
    getCars();
    setRefresh(!refresh);
  }

  function matchFilteredData(input){
    let temp = [];
    input.forEach(element => {
      filteredData.forEach(element2 => {
        if(element["NO"] == element2["NO"]){
          temp.push(element);
        }
      });
    });
    setFiltered(temp);
  }
  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(Dimensions.get('window').width);
    };

    Dimensions.addEventListener('change', handleResize);

    return () => {
      // Cleanup event listener on component unmount
      Dimensions.removeEventListener('change', handleResize);
    };
  }, []);

  return(
<View style={{ flex: 1, height: '100%' }}>
    {data.length === 0 ? (
        <ActivityIndicator
            animating={data.length === 0}
            color='#BABABA'
            size='large'
            style={{ marginTop: 40 }}
        />
    ) : (

            <View style={{ height: '100%', paddingHorizontal: 15,paddingVertical: 15, flex: 1, alignItems:'center' }}>
                {
                    screenWidth < 900 ?
                    <ListData  data={data} setCarData={chooseCar} /> :
                    <TableRSuite
                        getFiles={getFiles}
                        checkAll={checkAll}
                        checkedKeys={checkedKeys}
                        handleCheckChange={handleCheckChange}
                        reset={() => { reset(); }}
                        resetFilter={resetTableFilter}
                        refresh={refresh}
                        filterData={data}
                        setCarData={chooseCar}
                        ref={tableRef}
                        tableHead={tableHead}
                        data={isFiltered ? filteredData : data}
                        resetAfterUpdate={resetAfterUpdate}
                        initialData={data}
                        isFilterApplied={isFiltered}
                        setFiltered={setFiltered}
                        setApplied={filterApplied}
                        getFilterDataQuery= {getDataFiltered}
                    />
                }
            </View>

    )}
</View>
  );
}
);
  //

const ListData = (props) => {
  const [data,setData] = useState([]);
  const [refresh,setRefresh] = useState(false);
  const [activate,closeIndictor] = useState(true);
  const [isPopUpvisible,setPopUpVisible] = useState(false)
  const [choosenCar, setChoosenCar] = useState(undefined)
  const [CarChoosenRefresh,setCarChooseRefresh] = useState(false);
  const [isFiltered, setApplied] = useState(false);
  const [filteredData,setFiltered] = useState([]);
  const[choosen_s3KEY,setChoosenS3KEY] = useState("");
  const [PopupActive, setPopupActive] = useState(false);

  function handleModalClose(){
    setPopupActive(false);
  }

  function chooseCar(input){
    console.log(input);
    props.setCarData(input);
  }


  function view_car_licence(input){
    var dataS3 = JSON.parse(input["S3 KEYS LICENCES"]).data
    console.log(dataS3);
    if( dataS3 != null){
      dataS3.sort(function(a, b) {
        return parseFloat(b.ID) - parseFloat(a.ID);
    });}
    console.log(dataS3);
    setChoosenS3KEY(dataS3[0].S3_KEY);
    setPopupActive(true);
    // console.log(dataS3[0].s3_KEY);
  }

  const carlicenceModalRef = useRef();
  function viewCarLicenceEditPopup(rowData){
    carlicenceModalRef.current.open(rowData);
  }




  const ExpandableListItem = ({ item }) => {

    return(
      <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 10}}>
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
          <View style={{flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
            <CustomText style={{fontSize: 16, color: '#000', fontWeight :'700'}}>{item.PLAKA}</CustomText>
            <CustomText style={{fontSize: 16, color: '#E4002B'}}>{item["MODEL YILI"]}</CustomText>
            <CustomText style={{fontSize: 14, color: '#000'}}>{item["RENK"]}</CustomText>
            <CustomText style={{fontSize: 14, color: '#000'}}>{item["ARAÇ"]}</CustomText>
            <CustomText style={{fontSize: 14, color: '#000'}}>{item["ARAÇ DURUM"]}</CustomText>
            <CustomText style={{fontSize: 12, color: '#000'}}>{item["VİTES"] + " , " + item["YAKIT TİPİ"]}</CustomText>
            <CustomText style={{fontSize: 12, color: '#000'}}>{}</CustomText>
            <CustomText style={{fontSize: 12, color: '#000'}}>{item["KM"]}</CustomText>
          </View>
          </View>
          <View style={{flex: 1, flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center'}}>
            <IconButton style={{width: '60%'}} icon = {<Icon><FontAwesomeIcon icon = {'car'}></FontAwesomeIcon></Icon>} onClick={() => {chooseCar(item);}}>
              <CustomText style={{fontSize: 14  }}>Araç Bilgileri</CustomText>
            </IconButton>
            <View style = {{height: 10}}></View>
              {
              (JSON.parse(item["S3 KEYS LICENCES"]).data != null ) ?
              <IconButton  style={{width: '60%'}} icon={<Icon><FontAwesomeIcon icon={"file-circle-check"} /></Icon>}  onClick={() => {view_car_licence(item)}}> <CustomText> Ruhsat </CustomText>  </IconButton>
              :
              <IconButton style={{width: '60%'}}  icon={<Icon><FontAwesomeIcon icon={"file"} /></Icon>}  onClick={() => {  viewCarLicenceEditPopup(item); }} >   <CustomText> Ruhsat </CustomText></IconButton>
              }

            <View style = {{height: 10}}></View>
            <CarSummaryCardPdf data={item}></CarSummaryCardPdf>
            </View>
      </View>
    )
    }



  return(
    <View style={{ flex: 1, height: '100%' }}>
    <CarLicenceAddModal reset={props.reset} ref = {carlicenceModalRef} ></CarLicenceAddModal>
    <Drawer style={{width: '95%'}} backdrop={true} keyboard={false} open={PopupActive} onClose={handleModalClose}>
              <Drawer.Header>
                  {props.data["ARAÇ"]}
              </Drawer.Header>
              <Drawer.Body >
                  <FileViewer inline={true} s3KEY = {choosen_s3KEY}></FileViewer>
              </Drawer.Body >
      </Drawer>
      <List>
        { props.data.map((item, index) => {
        return (
          <List.Item>
            <ExpandableListItem item={item} />
          </List.Item>
        )
      })}
      </List>
    </View>
  )
}

  // filterItems = {filterItems} setFilterItems = {setFilterItems}
const TableRSuite = forwardRef( (props,ref) => {


  //#region Table comps

  const [PopupActive, setPopupActive] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const {
      page,
      limit,
      handlePageChange,
      handleLimitChange
  } = usePagination();

  const {
      sortColumn,
      sortType,
      handleSortColumn
  } = useTable();

  // console.log(props.data);
  const data =( props.data != null &&  props.data != undefined) && props.data.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });

  const toggle_refresh = () => {
      // console.log(refresh);
      setRefresh(!refresh);
  }

  function handleModalClose(){
    setPopupActive(false);
  }

  useImperativeHandle(ref, () => ({
      // methods connected to `ref`
      refresh: () => {
        toggle_refresh();
      },
      setPage1:() => {
        handlePageChange(1);
      },
      filter: () => {
        toggle_refresh();
      }
    }))

  const[choosen_s3KEY,setChoosenS3KEY] = useState("");

  //#region  Table Functions

  function view_car_licence(input){
    var dataS3 = JSON.parse(input["S3 KEYS LICENCES"]).data
    console.log(dataS3);
    if( dataS3 != null){
      dataS3.sort(function(a, b) {
        return parseFloat(b.ID) - parseFloat(a.ID);
    });}
    console.log(dataS3);
    setChoosenS3KEY(dataS3[0].S3_KEY);
    setPopupActive(true);
    // console.log(dataS3[0].s3_KEY);
  }
  function viewCarLicenceEditPopup(rowData){
    carlicenceModalRef.current.open(rowData);
  }
  function editCarPopup(rowData){
    updateCarRef.current.open(rowData);
  }
  function chooseCar(input){
    console.log(input);
    props.setCarData(input);
  }

  //#endregion

  //#region  Table Cell Renderers

  const ShowLicenceCell = ({ rowData, dataKey, ...props }) => (

      <Table.Cell {...props} >
        <View style={{ height: '100%'  }}>
          {(JSON.parse(rowData["S3 KEYS LICENCES"]).data[0].S3_KEY != null ) ?  <IconButton icon={<Icon><FontAwesomeIcon icon={"file"} /></Icon>}
            onClick={() => {view_car_licence(rowData)}}
          />
          :  <IconButton icon={<Icon><FontAwesomeIcon icon="file-arrow-up" /></Icon>}
            onClick={
            () => {
              viewCarLicenceEditPopup(rowData);
            }
          }
          >
          </IconButton>}
        </View>
      </Table.Cell>
  );

  const ActionCellEdit = ({ rowData, dataKey, ...props }) => (
      <Table.Cell {...props} >
        <View style={{ height: '100%'  }}>
          <IconButton icon={<Icon><FontAwesomeIcon icon={"pen"} /></Icon>}
            onClick={
            () => {
              editCarPopup(rowData);
            }
          }></IconButton>

        </View>
      </Table.Cell>
  );

  function editRowData(input,dataKey){
    if(dataKey.includes("TARİH") && input[dataKey] != null && input[dataKey] != undefined){
      return reverseDate(input[dataKey]);
    }else if(dataKey.includes("KASKO DEĞER") && input[dataKey] != null && input[dataKey] != undefined){
      return " ₺ " + (input[dataKey] + "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    else if(dataKey.includes("KM")){
      return (input[dataKey] + "").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " KM";
    }
    else{
      return input[dataKey];
    }
  }

  function reverseDate(input){
    var result = "";

    var comps = input.split("-");

    for(var c = 2 ; c >= 0 ; c--){
      for(var i = 0 ; i < comps[c].length  ; i++){
        result += comps[c][i];
    }
    c != 0 ? result += "-": result += "";
    }

    return result;
  }

  const CustomCellView = ({ rowData, dataKey, ...props }) => (
    <Table.Cell {...props}>
      {dataKey === "PLAKA" ? (
        <View style={{alignItems: 'center', justifyContent: 'center' , height: 40 }}>
          <Button onClick={() => { chooseCar(rowData); }}>
        <CustomText style={{fontFamily: 'Barlow-800'}}>   {rowData[dataKey]} </CustomText>
          </Button>
        </View>
      ) : (
        <View style={{ alignItems: 'flex-start', justifyContent: 'center', height: 40}}>
          <CustomText style={{ textAlign: 'left' }}>{editRowData(rowData, dataKey)}</CustomText>
        </View>
      )}
    </Table.Cell>
  );

  const CheckBoxCell = ({ rowData, checkChange }) => {
    return (

        <View style= {{alignItems: 'center'}}>
          <Checkbox
            checked={rowData["CHECKED"] == 1}
            onChange={(value, checked) => {
              checkChange(rowData, checked);
            }}
          />
        </View>
    );
  };
  //#endregion

  // SORT AND GETDATA
  function getData(){
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });

    }
    return data

  };

  const carlicenceModalRef = useRef();
  const updateCarRef = useRef();


  //#endregion

  //#region  Table Filters
  const [filterChange , setFilterChange] = useState(0);

  const plakaRef = useRef();
  const bmpref = useRef();
  const ruhsatRef = useRef();
  const modelYiliRef = useRef();
  const tescilTarihRef = useRef();
  const tescilNoRef = useRef();
  const muayeneRef = useRef();
  const kasaRef = useRef();
  const ccRef = useRef();
  const ccSınıfRef = useRef();
  const yakıtRef = useRef();
  const vitesRef = useRef();
  const hpRef = useRef();
  const renkRef = useRef();
  const KMRef = useRef();
  const bakimKMRef = useRef();
  const sasiNoRef = useRef();
  const motorNoRef = useRef();
  const carTagRef = useRef();
  const markaRef = useRef();
  const modelRef = useRef();
  const paketRef = useRef();
  const aracRef = useRef();
  const aracDurumRef = useRef();
  const muayeneRemRef = useRef();
  const kaskoDegerRef = useRef();
  const clientRef = useRef();
  // const refs = [plakaRef,aracDurumRef,tescilNoRef, sasiNoRef, motorNoRef, ruhsatRef, bmpref, modelYiliRef,
  //   muayeneRef, tescilTarihRef, kasaRef, ccRef, ccSınıfRef, yakıtRef, vitesRef, hpRef, renkRef,
  //    KMRef, bakimKMRef, carTagRef, markaRef, modelRef, paketRef, aracRef];

  const refs = [
      aracRef,             // refID: 0
      aracDurumRef,        // refID: 1
      sasiNoRef,           // refID: 2
      markaRef,            // refID: 3
      modelRef,            // refID: 4
      paketRef,            // refID: 5
      kasaRef,             // refID: 6
      motorNoRef,          // refID: 7
      ruhsatRef,           // refID: 8
      tescilNoRef,         // refID: 9
      tescilTarihRef,      // refID: 10
      modelYiliRef,        // refID: 11
      muayeneRef,          // refID: 12
      muayeneRemRef,          // refID: 13
      ccRef,               // refID: 14
      ccSınıfRef,          // refID: 15
      yakıtRef,            // refID: 16
      vitesRef,            // refID: 17
      hpRef,               // refID: 18
      KMRef,               // refID: 19
      bakimKMRef,          // refID: 20
      renkRef,             // refID: 21
      kaskoDegerRef,       // refID: 22
      plakaRef,           // refID: 23
      clientRef           // refID: 24
  ];


  function intersect (first, rest) {
    return first.filter(e => rest.every(item => checkContains(item,e)))
  }
  function checkContains(arr,check){
    return arr.indexOf(check) >= 0
  }
  function testMultipleFilter(input){
    let first =  input.shift();
    let final = intersect(first,input);
    console.log(final)
    props.setFiltered(final)
    props.setApplied(true)
  }

  function filter(){
  //free texts
    let final = [];

    refs.forEach(refItem => {
      if (refItem != null && refItem.current != null && refItem.current.filter() != null) {
        const arr = refItem.current.filter();
        final.push(arr);
      }
    });
    testMultipleFilter(final)
    // testMultipleFilter([arr1,arr2,arr3,arr4,arr18])
  }

  const item_style = {
    standard: { width : 120, marginVertical : 10 },
    viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
  }

  //#endregion


  //#region  Table Columns
  const [choosableColumns,setChoosenColumns] = useState(
    [
      { label: 'PLAKA', value: 'PLAKA' },
      { label: 'ARAÇ', value: 'ARAÇ' },
      { label: 'ARAÇ DURUM', value: 'ARAÇ DURUM' },
      { label: 'ŞASİ', value: 'ŞASİ' },
      { label: 'MARKA', value: 'MARKA' },
      { label: 'MODEL', value: 'MODEL' },
      { label: 'PAKET', value: 'PAKET' },
      { label: 'KASA', value: 'KASA' },
      { label: 'MOTORNO', value: 'MOTORNO' },
      { label: 'RUHSAT SAHİBİ', value: 'RUHSAT SAHİBİ' },
      { label: 'TESCİL BELGE NO', value: 'TESCİL BELGE NO' },
      { label: 'TESCİL TARİHİ', value: 'TESCİL TARİHİ' },
      { label: 'MODEL YILI', value: 'MODEL YILI' },
      { label: 'MUAYENE TARİHİ', value: 'MUAYENE TARİHİ' },
      { label: 'KALAN MUAYENE GÜNÜ', value: 'KALAN MUAYENE GÜNÜ' },
      { label: 'CC', value: 'CC' },
      { label: 'CC SINIFI', value: 'CC SINIFI' },
      { label: 'YAKIT TİPİ', value: 'YAKIT TİPİ' },
      { label: 'VİTES', value: 'VİTES' },
      { label: 'HP', value: 'HP' },
      { label: 'ARAÇ KM', value: 'ARAÇ KM' },
      { label: 'BAKIM KM PERİYODU', value: 'BAKIM KM PERİYODU' },
      { label: 'RENK', value: 'RENK' },
      { label: 'KASKO DEĞERİ', value: 'KASKO DEĞERİ' },
      { label: 'MÜŞTERİ ADI', value: 'MÜŞTERİ ADI' },

    ]
  );
  const columnCreateData=[
    {
      dataKey: 'ARAÇ',
      label: 'Araç',
      refID: 0,
      isActive: "1",
      filterType: 'FreeTextFilter',
      width: 300,
    },
    {
      dataKey: 'ARAÇ DURUM',
      label: 'Araç Durumu',
      refID: 1,
      isActive: "1",
      filterType: 'TagPickerFilter',
      width: 400,

    },
    {
      dataKey: 'ŞASİ',
      label: 'Şasi No',
      refID: 2,
      isActive: "1",
      filterType: 'FreeTextFilter',
      width: 200,

    },
    {
      dataKey: 'MARKA',
      label: 'Marka',
      refID: 3,
      isActive: "1",
      filterType: 'TagPickerFilter',
      width: 150,
    },
    {
      dataKey: 'MODEL',
      label: 'Model',
      refID: 4,
      isActive: "1",
      filterType: 'TagPickerFilter',
      width: 150,
      sortable : true,

    },
    {
      dataKey: 'PAKET',
      label: 'Paket',
      refID: 5,
      isActive: "1",
      filterType: 'TagPickerFilter',
      width: 150,
    },
    {
      dataKey: 'KASA',
      label: 'Kasa',
      refID: 6,
      isActive: "1",
      filterType: 'TagPickerFilter',
      width: 150,

    },
    {
      dataKey: 'MOTORNO',
      label: 'Motorno',
      refID: 7,
      isActive: "1",
      filterType: 'FreeTextFilter',
      width: 150,

    },

    {
      dataKey: 'RUHSAT SAHİBİ',
      label: 'Ruhsat Sahibi',
      refID: 8,
      isActive: "1",
      filterType: 'TagPickerFilter',
      width: 150,

    },
    {
      dataKey: 'TESCİL BELGE NO',
      label: 'Tescil Belge No',
      refID: 9,
      isActive: "1",
      filterType: 'FreeTextFilter',
      width: 150,

    },
    {
      dataKey: 'TESCİL TARİHİ',
      label: 'Tescil Tarihi',
      refID: 10,
      isActive: "1",
      filterType: 'DateRangeFilter',
      width: 150,

    },
    {
      dataKey: 'MODEL YILI',
      label: 'Model Yılı',
      refID: 11,
      isActive: "1",
      filterType: 'TagPickerFilter',
      width: 150,

    },
    {
      dataKey: 'MUAYENE TARİHİ',
      label: 'Muayene Tarihi',
      refID: 12,
      isActive: "1",
      filterType: 'DateRangeFilter',
      width: 250,

    },
    {
      dataKey: 'KALAN MUAYENE GÜNÜ',
      label: 'Kalan Muayene Günü',
      refID: 13,
      isActive: "1",
      filterType: 'BetweenBeyondFilter',
      width: 250,

    },
    {
      dataKey: 'CC',
      label: 'CC',
      refID: 14,
      isActive: "1",
      filterType: 'BetweenBeyondFilter',
      width: 150,

    },
    {
      dataKey: 'CC SINIFI',
      label: 'CC (Silindir)',
      refID: 15,
      isActive: "1",
      filterType: 'TagPickerFilter',
      width: 150,

    },
    {
      dataKey: 'YAKIT TİPİ',
      label: 'Yakıt Tipi',
      refID: 16,
      isActive: "1",
      filterType: 'TagPickerFilter',
      width: 150,

    },
    {
      dataKey: 'VİTES',
      label: 'Vites',
      refID: 17,
      isActive: "1",
      filterType: 'TagPickerFilter',
      width: 150,

    },
    {
      dataKey: 'HP',
      label: 'HP',
      refID: 18,
      isActive: "1",
      filterType: 'SliderFilter',
      width: 150,

    },
    {
      dataKey: 'ARAÇ KM',
      label: 'Araç KM',
      refID: 19,
      isActive: "1",
      filterType: 'BetweenBeyondFilter',
      width: 150,

    },
    {
      dataKey: 'BAKIM KM PERİYODU',
      label: 'Bakım KM Periyodu',
      refID: 20,
      isActive: "1",
      filterType: 'TagPickerFilter',
      width: 250,

    },
    {
      dataKey: 'RENK',
      label: 'Renk',
      refID: 21,
      isActive: "1",
      filterType: 'TagPickerFilter',
      width: 150,

    },
    {
      dataKey: 'KASKO DEĞERİ',
      label: 'Kasko Değeri',
      refID: 22,
      isActive: "1",
      filterType: 'BetweenBeyondFilter',
      width: 150,
    },
    {
      dataKey: 'MÜŞTERİ ADI',
      label: 'Müşteri Adı',
      refID: 23,
      isActive: "1",
      filterType: 'TagPickerFilter',
      width: 150,
    }
    ];


  const [columns,setColumns] = useState([
    "PLAKA",
    "ARAÇ",
    "ARAÇ DURUM",
    "MÜŞTERİ ADI",
    "MODEL YILI",
    "YAKIT TİPİ",
    "VİTES",
    "ARAÇ KM",
    "RENK",

  ])

  const columnsAll = [
    "PLAKA",
    "ARAÇ",
    "ARAÇ DURUM",
    "MÜŞTERİ ADI",
    "ŞASİ",
    "MARKA",
    "MODEL",
    "PAKET",
    "KASA",
    "MOTORNO",
    "RUHSAT SAHİBİ",
    "TESCİL BELGE NO",
    "TESCİL TARİHİ",
    "MODEL YILI",
    "MUAYENE TARİHİ",
    "KALAN MUAYENE GÜNÜ",
    "CC",
    "CC SINIFI",
    "YAKIT TİPİ",
    "VİTES",
    "HP",
    "ARAÇ KM",
    "BAKIM KM PERİYODU",
    "RENK",
    "KASKO DEĞERİ",

  ]
  const [columnsCreate,setColumnsCreate] = useState(
    [
      {"dataKey" : "ARAÇ", "checked": true , "sortable": true, width : 300},
      {"dataKey" : "ARAÇ DURUM", "checked": true, "sortable": true, width : 150 },
      {"dataKey" : "MÜŞTERİ ADI", "checked": true , "sortable": true, width : 150},
      {"dataKey" : "ŞASİ", "label" : "Şasi"  ,"checked": false , "sortable": true, width : 200},
      {"dataKey" : "MARKA", "checked": false , "sortable": true, width : 150},
      {"dataKey" : "MODEL", "checked": false , "sortable": true, width : 150},
      {"dataKey" : "PAKET", "checked": false , "sortable": true, width : 150},
      {"dataKey" : "KASA", "checked": false , "sortable": true, width : 150},
      {"dataKey" : "MOTORNO", "checked": false , "sortable": true, width : 150},
      {"dataKey" : "RUHSAT SAHİBİ", "label" : "Ruhsat Sahibi", "checked": false , "sortable": true, width : 150},
      {"dataKey" : "TESCİL BELGE NO", "label" : "Tescil Belge No" ,"checked": false , "sortable": true, width : 150},
      {"dataKey" : "TESCİL TARİHİ", "label" : "Tescil Tarihi" , "checked": false , "sortable": true, width : 150},
      {"dataKey" : "MODEL YILI", "label" : "Model Yılı",  "checked": true , "sortable": true, width : 130},
      {"dataKey" : "MUAYENE TARİHİ",  "label" : "Muayene Tarihi", "checked": false , "sortable": true, width : 150},
      {"dataKey" : "KALAN MUAYENE GÜNÜ", "checked": false , "sortable": true, width : 150},
      {"dataKey" : "CC", "checked": false , "sortable": true, width : 150},
      {"dataKey" : "CC SINIFI", label: 'CC (Silindir)', "checked": false , "sortable": true, width : 150},
      {"dataKey" : "YAKIT TİPİ","label" : "Yakıt Tipi" , "checked": true , "sortable": true, width : 130},
      {"dataKey" : "VİTES", "label" : "Vites Tipi" ,"checked": true , "sortable": true, width : 130},
      {"dataKey" : "HP", "checked": false , "sortable": true, width : 150},
      {"dataKey" : "ARAÇ KM", "checked": true , "sortable": true, width : 150},
      {"dataKey" : "BAKIM KM PERİYODU", "checked": false , "sortable": true, width : 150},
      {"dataKey" : "RENK", "checked": true , "sortable": true, width : 150},
      {"dataKey" : "KASKO DEĞERİ", "checked": false , "sortable": true, width : 150},
    ]
  );

  const [filterQueries, setFilterQueries] = useState([
    {
      label: "SATIN ALMA OLMAYANLAR",
      queryType: "NULL_CHECK",
      queryCheck: "BUY_INFO",
    },
    {
        label: "RUHSAT OLMAYANLAR",
        queryType: "NULL_CHECK",
        queryCheck: "CAR_LICENSE",
    }
  ]);

  function applyChoosenFilterQuery(item){
      console.log(item);
      props.getFilterDataQuery(item);

  }



  useEffect(() => {

    let temp = []
    columnsCreate.forEach(element => {
      let tempItem = {...element};
      if(columns.includes(element.dataKey)){
        tempItem.checked = true;
      }else{
        tempItem.checked = false;
      }
      temp.push(tempItem);
    });

    setColumnsCreate(temp);
    console.log(columns);
  },[columns])

  useEffect(() => {
    refs.forEach(element => {
      if(element.current != null){
        element.current.types();

      }else{
        console.log(element);
      }
    });
    setRefresh(!refresh);
  },[columnsCreate])

  //#endregion



  const [checkedAll,setCheckAll] = useState(false);

  function getItemInfo (item) {
    let result = columnCreateData.find((obj) =>
      obj.dataKey === item
    )
    return result;
  }

  function getParseAraçDurum(){
    const api = 'https://a16d1wffub.execute-api.eu-central-1.amazonaws.com/dev/uidropdownlist';
    axios
    .post(api,{
      "ENV" :configdata.APP_ENV ,
        "category": "ARAÇ_DURUM",
        "hasParent": "NO",
        "type" : "GET",

    })
    .then((response) => {
      // console.log(response);
      // console.log(response.data);
      //setUserData(JSON.parse(response.data));

      var result = [];
      response.data.forEach( element =>
        {
          if(element.ID  != 1){
            if(element.PARENT == -1){
              result.push({"label" : element.İSİM , "value" : element.ID, children : []})
            }
          }

        }
        );

        response.data.forEach( element =>
        {
          if(element.PARENT != -1){
            result.find( parent => parent.value == element.PARENT).children.push({"label" : element.İSİM , "value" : element.ID})
          }
        });
      setAraçDurumlar(result);

    })
    .catch((error) => {
      console.log(error);

    });
  }

  useEffect(() => {
    getParseAraçDurum();
  },[])

  const [araçDurumlar,setAraçDurumlar] = useState([]);
  const [optionsActive,setOptionsActive] = useState(false);
  const [araçDurumPopup,setAraçDurumPopup] = useState(false);
  const [araçDurumValue,setAraçDurumValue] = useState(1);
  const [araçDurumPopupTransfer,setAraçDurumPopupTransfer] = useState(false);

  const { messageToggle } = useMessage();
  const toggleAraçDurumPopup = () => {


    let selectedRows = props.data.filter((v, i) => {
      return v["CHECKED"] == 1;
    });
      if(selectedRows.length == 0){
          messageToggle("Lütfen araç seçiniz", "error");
      }else{
        let cond = true;

        selectedRows.forEach((row) => {
          if(row["ARAÇ_DURUM_ID"].toString().startsWith("3")){
            cond = false;
          }
        });
        if(!cond){
          messageToggle("Seçilen araçlar kirada olduğu için araç durumu değiştirilemez", "error");
        }else{
            setAraçDurumPopup(!araçDurumPopup);
            setOptionsActive(false);
        }
      }


  }

  const toggleAraçDurumPopupTransfer = () => {
    let selectedRows = props.data.filter((v, i) => {
      return v["CHECKED"] == 1;
    });
      if(selectedRows.length == 0){
          messageToggle("Lütfen araç seçiniz", "error");
      }else{
        let cond = true;

        selectedRows.forEach((row) => {
          if(row["ARAÇ_DURUM_ID"].toString().startsWith("3")){
            cond = false;
          }
        });
        if(!cond){
          messageToggle("Seçilen araçlar kirada olduğu için araç durumu değiştirilemez", "error");
        }else{
            setAraçDurumPopupTransfer(!araçDurumPopupTransfer);
            setOptionsActive(false);
        }

      }
  }

  const { fetchIdToken } = useUserToken();




  const handleCheckAll = (value, checked) => {
    setColumns(checked ? columnsAll : ["PLAKA"]);
    let result = [];
  };

  function formatHeaderText(input){
    var result = "";
    result += input[0].toUpperCase();
    for(var i = 1 ; i < input.length ; i++){
      if(input[i] == "_"){
        result += " ";
      }else{
        result += input[i].toLowerCase();
      }
    }
    return result;
  }

  function handleChangeColumns(value){
    setColumns(value);
  }

  return (
      <View style={{flex: 1, width: '100%'}}>
          <Drawer style={{width: '95%'}} backdrop={true} keyboard={false} open={PopupActive} onClose={handleModalClose}>
              <Drawer.Header>
                  {props.data["S3 KEYS LICENCES"] != undefined ? typeof JSON.parse(props.data["S3 KEYS LICENCES"]).data.S3_KEY : ""}
              </Drawer.Header>
              <Drawer.Body>
                  <FileViewer inline={true} s3KEY={choosen_s3KEY}></FileViewer>
              </Drawer.Body>
          </Drawer>
          <Drawer overflow={true} size={'full'} backdrop={'static'} keyboard={false} open={araçDurumPopup}
                  onClose={toggleAraçDurumPopup}>
              <Drawer.Body style={{backgroundColor: '#F2F2F2'}}>
                  <NewRentAggreementDrawer cars={
                      props.data.filter((v, i) => {
                          return v["CHECKED"] == 1;
                      })} onClose={toggleAraçDurumPopup}></NewRentAggreementDrawer>
              </Drawer.Body>
          </Drawer>
          <Drawer overflow={true} size={'md'} backdrop={'static'} keyboard={false} open={araçDurumPopupTransfer}
                  onClose={toggleAraçDurumPopupTransfer}>
              <Drawer.Body>
                  <TransferCarDrawer data={
                      props.data.filter((v, i) => {
                          return v["CHECKED"] == 1;
                      })}
                                     setAraçDurumPopup={setAraçDurumPopupTransfer}
                                     resetAfterUpdate={props.resetAfterUpdate}
                                     onClose={toggleAraçDurumPopupTransfer}></TransferCarDrawer>
              </Drawer.Body>
          </Drawer>
          <CarLicenceAddModal reset={props.reset} ref={carlicenceModalRef}></CarLicenceAddModal>
          <EditCarModal reset={props.reset} ref={updateCarRef}></EditCarModal>

          <View style={{
              padding: 13,
              borderRadius: 7,
              height: 80,
              backgroundColor: 'white',
              flexDirection: 'row',
              marginBottom: 15
              ,
              shadowColor: '#BDC9FF',
              shadowOffset: {width: 0, height: 1},
              shadowOpacity: 0.3,
              shadowRadius: 12,
              elevation: 5
          }}>
              <Whisper
                  open={optionsActive}
                  placement="bottomStart"
                  speaker={
                      <Popover title={"Seçenekler"} placement="bottomStart">
                          <View style={{
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              marginVertical: 10,
                              height: 30,
                              marginHorizontal: 12
                          }}>
                              <IconButton onClick={props.getFiles} style={{backgroundColor: '#317ae0', width: 250}}
                                          icon={<Icon> <FontAwesomeIcon icon={"file-zipper"}></FontAwesomeIcon>
                                          </Icon>}>
                                  <CustomText style={{fontWeight: 'bold', color: '#EBFFF9'}}> Ruhsatları
                                      İndir </CustomText>
                              </IconButton>
                          </View>
                          <View style={{
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              marginVertical: 10,
                              height: 30,
                              marginHorizontal: 12
                          }}>
                              <IconButton onClick={toggleAraçDurumPopup}
                                          style={{backgroundColor: '#FFC100', width: 250}} icon={<Icon> <FontAwesomeIcon
                                  icon={"arrow-right-arrow-left"}></FontAwesomeIcon> </Icon>}>
                                  <CustomText style={{fontWeight: 'bold', color: '#fff'}}> Kira Sözleşmesi
                                      Oluştur </CustomText>
                              </IconButton>
                          </View>
                          <View style={{
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              marginVertical: 10,
                              height: 30,
                              marginHorizontal: 12
                          }}>
                              <IconButton onClick={toggleAraçDurumPopupTransfer}
                                          style={{backgroundColor: '#FFC100', width: 250}} icon={<Icon> <FontAwesomeIcon
                                  icon={"arrow-right-arrow-left"}></FontAwesomeIcon> </Icon>}>
                                  <CustomText style={{fontWeight: 'bold', color: '#fff'}}> Araç Transfer </CustomText>
                              </IconButton>
                          </View>

                          <ExportData width={250} data={props.data} tableHead={props.tableHead} columns={columns}
                                      type={"CHECKED"} tableName={"ARAÇLAR"}></ExportData>
                          <ExportData width={250} data={props.data} tableHead={props.tableHead} columns={columns}
                                      tableName={"ARAÇLAR"}></ExportData>
                      </Popover>
                  }
              >
                  <View style={{
                      flexDirection: 'row',
                      width: '98%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginVertical: 10,
                      height: 30,
                      marginHorizontal: 12
                  }}>
                      <Stack>
                          <IconButton onClick={() => {
                              setOptionsActive(!optionsActive)
                          }} style={{backgroundColor: '#248AFD', width: 150}}
                                      icon={<Icon><FontAwesomeIcon icon={"gear"}></FontAwesomeIcon></Icon>}>
                              <CustomText style={{color: '#EBFFF9'}}> Seçenekler</CustomText>
                          </IconButton>
                          <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                              <CustomText style={{
                                  fontSize: 16,
                                  color: '#248AFD',
                                  textAlign: 'center',
                                  marginVertical: 8,
                                  marginHorizontal: 10
                              }}> Sütun Yönetimi :</CustomText>

                              <CheckPicker
                                  placement='autoVerticalStart'
                                  disabledItemValues={["PLAKA"]}
                                  value={columns}
                                  onChange={(value) => {
                                      handleChangeColumns(value);
                                  }}
                                  data={choosableColumns}
                                  style={{width: 300}}
                                  renderValue={(value, item) => {
                                      return (
                                          <div style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                              width: '100%',
                                          }}>
                                              <CustomText fontWeight='800' style={{
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  whiteSpace: 'nowrap',
                                                  color: '#248AFD',
                                              }}>
                                                  {"(" + value.length + " )  : " + value.join(', ')}
                                              </CustomText>
                                          </div>
                                      );
                                  }}
                                  renderMenuItem={(label, item) => {
                                      return (
                                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                              <CustomText>
                                                  {item.value}
                                              </CustomText>
                                          </div>
                                      );
                                  }}
                                  renderExtraFooter={() => (
                                      <div style={{padding: '10px 2px', borderTop: '1px solid #e5e5e5'}}>
                                          <Checkbox checked={columns.length === columnsAll.length}
                                                    onChange={handleCheckAll}>
                                              <CustomText> Tümünü Seç </CustomText>
                                          </Checkbox>
                                      </div>
                                  )}
                              />

                          </View>
                      </Stack>
                  </View>


              </Whisper>

          </View>
          <Panel shaded style={{backgroundColor: 'white'}}>
              <Stack style={{
                  width: '100%',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'center'
              }}>
                  {filterQueries.map((item, index) => (
                      <Stack.Item key={index} index={index} style={{padding: 10}}>
                          <Button onClick={() => applyChoosenFilterQuery(item)}
                                  style={{backgroundColor: '#FFC100', width: 250}}>
                              <CustomText style={{
                                  color: '#015958',
                                  fontWeight: 'bold',
                                  textAlign: 'center'
                              }}>{item.label}</CustomText> </Button>

                      </Stack.Item>
                  ))}

              </Stack>
          </Panel>

          <View style={{
              marginTop: 15,
              borderRadius: 7,
              shadowColor: '#BDC9FF',
              shadowOffset: {width: 0, height: 1},
              shadowOpacity: 0.2,
              shadowRadius: 12,
              elevation: 5,
              backgroundColor: 'white'
          }}>
              <Table

                  wordWrap="break-word"
                  sortColumn={String(sortColumn)} sortType={typeof (sortType) == "string" ? sortType : "desc"}
                  onSortColumn={handleSortColumn}
                  data={getData()}
                  // height={Dimensions.get('window').height * 0.7}
                  autoHeight={true}
                  headerHeight={120}
                  rowHeight={40}
                  style={{borderRadius: 12}}
                  bordered
                  cellBordered

              >
                  <Table.Column width={90}>
                      <Table.HeaderCell>
                          <View style={{height: 45}}> </View>
                          <View style={{flexDirection: 'row', alignItems: 'center'}}>
                              <CustomText
                                  style={{color: '#015958', fontWeight: 'bold', textAlign: 'center'}}> Seç </CustomText>
                              <Checkbox
                                  checked={checkedAll}
                                  onChange={(value, checked) => {
                                      props.checkAll(checked);
                                      setCheckAll(checked);
                                  }}
                              >
                              </Checkbox>
                          </View>
                      </Table.HeaderCell>
                      <Table.Cell dataKey="CHECKED">
                          {(rowData) => (
                              <CheckBoxCell rowData={rowData} checkChange={props.handleCheckChange}/>
                          )}
                      </Table.Cell>
                  </Table.Column>
                  <Table.Column width={60}>
                      <Table.HeaderCell>
                          <View style={{height: 40}}> </View>
                          <CustomText style={{color: '#015958', fontWeight: 'bold', textAlign: 'center'}}> </CustomText>
                      </Table.HeaderCell>
                      <ActionCellEdit dataKey='EDIT'/>
                  </Table.Column>
                  <Table.Column width={60} sortable key={'RUHSAT'}>
                      <Table.HeaderCell>
                          <View style={{height: 40}}> </View>
                          <CustomText style={{color: '#015958', fontWeight: 'bold', textAlign: 'center'}}> </CustomText>
                      </Table.HeaderCell>
                      <ShowLicenceCell dataKey='RUHSAT'/>
                  </Table.Column>

                  <Column width={150} sortable>
                      <Table.HeaderCell>
                          <FreeTextFilter filter={filter} item_style={{width: 120}}
                                          refresh={toggle_refresh} ref={plakaRef}
                                          data={props.filterData}
                                          tableHeadName={"PLAKA"}
                                          setApplied={props.setApplied}
                                          setFiltered={props.setFiltered}
                                          reset={props.resetFilter}
                                          isFilterApplied={props.isFilterApplied}></FreeTextFilter>
                          <CustomText
                              style={{color: '#015958', fontWeight: 'bold', textAlign: 'center'}}> Plaka </CustomText>
                      </Table.HeaderCell>
                      <CustomCellView dataKey={"PLAKA"}></CustomCellView>
                  </Column>
                  {columnsCreate.map((item) => {
                      return (
                          item.checked &&
                          <Table.Column sortable={item.sortable} key={item.dataKey} width={item.width}>
                              <Table.HeaderCell>

                                  <FilterCompChooser
                                      filterType={getItemInfo(item.dataKey).filterType}
                                      filter={filter}
                                      item_style={{width: getItemInfo(item.dataKey).width - 20}}
                                      refresh={toggle_refresh}
                                      data={props.filterData}
                                      tableHeadName={item.dataKey}
                                      setApplied={props.setApplied}
                                      setFiltered={props.setFiltered}
                                      reset={props.resetFilter}
                                      isFilterApplied={props.isFilterApplied}
                                      filterRef={refs[getItemInfo(item.dataKey).refID]}
                                  />
                                  <CustomText style={{color: "#015958", fontWeight: "bold", textAlign: "center"}}>
                                      {item.label != undefined ? item.label : formatHeaderText(item.dataKey)}
                                  </CustomText>
                              </Table.HeaderCell>
                              <CustomCellView dataKey={item.dataKey}/>
                          </Table.Column>

                      );
                  })}
              </Table>
              <View style={{height: 100, alignItems: 'center', justifyContent: 'center', width: '90%'}}>
                  <Pagination
                      prev
                      next
                      first
                      last
                      ellipsis
                      boundaryLinks
                      maxButtons={5}
                      size="xs"
                      layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                      total={props.data.length}
                      limitOptions={[10, 20, 30, 40, 50, 100]}
                      limit={limit}
                      activePage={page}
                      onChangePage={handlePageChange}
                      onChangeLimit={handleLimitChange}
                      locale={{
                          more: 'Daha',
                          prev: 'Önceki',
                          next: 'Sonraki',
                          first: 'İlk',
                          last: 'Son',
                          limit: '{0} / sayfa',
                          total: 'Toplam Satır: {0}',
                          skip: 'Git {0}'
                      }}
                  />
              </View>
              <View style={{height: 20}}></View>
          </View>
      </View>
  )

})

const TransferCarDrawer = (props) => {

    const {fetchIdToken} = useUserToken();
    const {getParseAraçDurum} = useDropdownData();

    async function updateAraçDurum() {
        const api = configdata.API_BASE + "/cardata"
    const idToken = await fetchIdToken();
    const headers = {
      'Authorization': `Bearer ${idToken}`,
      'Content-Type': 'application/json'
    }

    let selectedRows = props.data.filter((v, i) => {
      return v["CHECKED"] == 1;
    });

    console.log(sessionStorage.getItem("user"));
    const attributesC =   JSON.parse(sessionStorage.getItem("user"))
      const exportData = [];
      selectedRows.forEach((row) => {
              const header = {
                "ARAÇ_DURUM" : araçDurumValue,
                "ID" : row["NO"],
              }
              console.log(header);
              exportData.push(header);

            });
      const body ={
        "ENV" :configdata.APP_ENV ,
        "type": "UPDATE_MULTIPLE_CAR",
        "data": exportData,
         "USER" :   attributesC["custom:rds_user_id"]
      }
    console.log("Selected Araç Durum Value:", araçDurumValue); // Log dropdown value
    console.log("Export Data:", exportData); // Log constructed data for backend
    console.log("Request Body:", body); // Log the final request body
      axios.post(api,body,{headers: headers})
      .then((response) => {
        console.log(response);
        if(response.data[0].includes ("SUCCESS")){
          props.setAraçDurumPopup(false);
          props.resetAfterUpdate();

        }
      }
    ).catch((error) => {
        console.log(error);
      })
  }

  const [araçDurumValue,setAraçDurumValue] = useState(1);
  const [araçDurumlar,setAraçDurumlar] = useState([]);

  useEffect(() => {
    getParseAraçDurum(setAraçDurumlar);
  },[])

  return (
    <View style = {{flex : 1, alignItems :'center' }}>
        <SelectPicker data={araçDurumlar} onChange={(value) => {setAraçDurumValue(value)}} value={araçDurumValue} style={{ width: 250 }} />
        <View style = {{height: 20}}></View>
        <IconButton onClick={updateAraçDurum} style={{width: 250}} icon={<Icon> <FontAwesomeIcon icon={"arrow-right-arrow-left"} ></FontAwesomeIcon> </Icon>}>
        Araç Durumunu Değiştir
        </IconButton>
    </View>
  )
}

const styles = StyleSheet.create({
  bottomBar: {
    height: 40,
    backgroundColor: '#fff',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent:'flex-start',
  },

      HeadStyle: {
        height: 50,
        alignContent: "center",
        backgroundColor: '#f7f7f5',
        // borderWidth: "1px",
        //  borderColor: "black"
      },
      RowStyle: {
        height: 40,
        alignContent: "center",
        backgroundColor: '#fff'
      },
      TableText: {
        textAlign: 'center',
        margin: 10
      },
      row: { flexDirection: 'row', backgroundColor: '#fff', height: 40,
    // borderColor: "black", borderWidth : "1px"
      },
      headerRow: { flexDirection: 'row', backgroundColor: '#f7f7f5', height: 40,
      // borderColor: "black", borderWidth : "1px"
      },
      btn: { width: "100%", },
      btnText: { textAlign: 'center', color: 'black' },
      modalView: {
        width: "95%",
        height:"95%",
        margin: 5,
        backgroundColor: "white",
        borderRadius: 7,
        padding: 5,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
      },
      modalText: {
        marginBottom: 15,
        textAlign: "center"
      }
})

export default CarsManagementScreenWTabs;
