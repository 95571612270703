'use client';
import Transition from "./Transition.js";
import Slide from "./Slide.js";
import Collapse from "./Collapse.js";
import Fade from "./Fade.js";
import Bounce from "./Bounce.js";
var Animation = {
  Transition: Transition,
  Collapse: Collapse,
  Fade: Fade,
  Bounce: Bounce,
  Slide: Slide
};
export default Animation;