import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import {Panel, Button, ButtonGroup, Table, Grid, Row, Col, DateRangePicker, Pagination} from 'rsuite';
import axios from 'axios';
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import configdata from "../../config/config.json";
import { useUserToken } from "../../hooks/useUserToken";
import CustomText from "../../Components/CustomText";
import 'moment/locale/tr';
import * as XLSX from 'xlsx';
moment.locale('tr');
//fomrat to tl as ₺ 123.456,01
function formatTL(amount) {
    return new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
}

function formatKM(amount) {
    return new Intl.NumberFormat('tr-TR', {
        style: 'unit',
        unit: 'kilometer',
        unitDisplay: 'short',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(amount);
}


const exportToExcel = (data, filename = 'MaintenanceData.xlsx') => {
    // Convert data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Maintenance Data');

    // Write the file
    XLSX.writeFile(workbook, filename);
};


const MaintenanceScreen = () => {
    const [maintenanceData, setMaintenanceData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [timeFilter, setTimeFilter] = useState('monthly'); // Default filter
    const [loading, setLoading] = useState(true);
    const [dailyData, setDailyData] = useState([]);

    const { fetchIdToken } = useUserToken();

    useEffect(() => {
        fetchMaintenanceData();
    }, []);

    useEffect(() => {
        const aggregatedData = groupByDay(filteredData);
        setDailyData(aggregatedData);
    }, [filteredData]);

    // Fetch data from AWS Lambda
    const fetchMaintenanceData = async () => {
        setLoading(true);
        const idToken = await fetchIdToken();
        const headers = {
            "Authorization": `Bearer ${idToken}`
        };

        const api = configdata.API_BASE + '/cardata';
        const body = {
            "type": "GET_MULTIPLE_MAINTENANCE_DATA",
            "ENV": configdata.APP_ENV
        };

        try {
            const response = await axios.post(api, body, { headers });
            console.log('Maintenance data:', response.data);
            setMaintenanceData(response.data);
            applyFilter(response.data, 'monthly');
        } catch (error) {
            console.error('Error fetching maintenance data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Filter data based on time period
    const applyFilter = (data, period, start, end) => {
        let filtered;

        switch (period) {
            case 'weekly':
                filtered = data.filter(item =>
                    moment(item.date).isBetween(moment().startOf('week'), moment().endOf('week'))
                );
                break;
            case 'monthly':
                filtered = data.filter(item =>
                    moment(item.date).isBetween(moment().startOf('month'), moment().endOf('month'))
                );
                break;
            case 'yearly':
                filtered = data.filter(item =>
                    moment(item.date).isBetween(moment().startOf('year'), moment().endOf('year'))
                );
                break;
            case 'custom':
                filtered = data.filter(item =>
                    moment(item.date).isBetween(moment(start), moment(end))
                );
                break;
            default:
                filtered = data;
        }

        setFilteredData(filtered);
        setTimeFilter(period);
    };

    const groupByDay = (data) => {
        const grouped = data.reduce((acc, item) => {
            const dateKey = moment(item.date).format('YYYY-MM-DD');
            if (!acc[dateKey]) {
                acc[dateKey] = 0;
            }
            acc[dateKey] += item.amount;
            return acc;
        }, {});

        return Object.keys(grouped).map(date => ({
            date,
            total: grouped[date],
        }));
    };



    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const { Column, HeaderCell, Cell: TableCell } = Table;

    return (
        <View style={{ padding: 20 }}>
            <Panel header="Maintenance Expenses" shaded style={styles.panel}>

                <ButtonGroup>
                    <Button
                        appearance={timeFilter === 'weekly' ? 'primary' : 'default'}
                        onClick={() => applyFilter(maintenanceData, 'weekly')}
                    >
                        Bu Hafta
                    </Button>
                    <Button
                        appearance={timeFilter === 'monthly' ? 'primary' : 'default'}
                        onClick={() => applyFilter(maintenanceData, 'monthly')}
                    >
                        Ay {moment().format('MMMM')}
                    </Button>
                    <Button
                        appearance={timeFilter === 'yearly' ? 'primary' : 'default'}
                        onClick={() => applyFilter(maintenanceData, 'yearly')}
                    >
                        Yıl {moment().year()}
                    </Button>
                    <DateRangePicker
                        placeholder="Tarih Aralığı Seç"
                        onChange={value => {
                            if (value) {
                                applyFilter(maintenanceData, 'custom', value[0], value[1]);
                            }
                        }}
                    />

                    <Button
                        appearance="primary"
                        onClick={() => exportToExcel(filteredData, 'MaintenanceData.xlsx')}
                    >
                        Export to Excel
                    </Button>
                </ButtonGroup>

                <Grid fluid style={{ marginTop: 20 }}>
                    <CustomText fontSize = {17}> Toplam Tutar: {formatTL(filteredData.reduce((acc, item) => acc + item.amount, 0))}</CustomText>

                    <Row>
                        <CustomText fontSize = {15}> Günlük Tablo</CustomText>
                        <ResponsiveContainer height={300} width="100%">
                            <BarChart data={dailyData} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip
                                    content={({ active, payload, label }) => {
                                        if (active && payload && payload.length) {
                                            return (
                                                <div style={{ background: 'white', border: '1px solid #ccc', padding: '10px', borderRadius: '4px' }}>
                                                    <p><strong>Date:</strong> {label}</p>
                                                    <p><strong>Total:</strong> {formatTL(payload[0].value)} TL</p>
                                                </div>
                                            );
                                        }
                                        return null;
                                    }}
                                />
                                <Bar dataKey="total" fill="#82ca9d">
                                    {dailyData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </Row>
                    <Row>
                            <CustomText ontSize = {15} > Plaka Bazlı Tablo</CustomText>
                            <ResponsiveContainer height={300} width="100%">
                                <BarChart data={filteredData} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                                    <XAxis dataKey="PLAKA" />
                                    <YAxis />
                                    <Tooltip
                                        content={({ active, payload, label }) => {
                                            if (active && payload && payload.length) {

                                                return (
                                                    <div style={{ background: 'white', border: '1px solid #ccc', padding: '10px', borderRadius: '4px' }}>
                                                        <p><strong>PLAKA:</strong> {label}</p>
                                                        <p><strong>Tutar:</strong> {formatTL(payload[0].value)} TL</p>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        }}
                                    />
                                    <Bar dataKey="amount" fill="#8884d8">
                                        {filteredData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                         </Row>


                </Grid>

                <MaintenanceTable data={filteredData} />
            </Panel>
        </View>
    );
};

const styles = {
    panel: {
        marginBottom: 20,
        backgroundColor: 'white',
    },
};


const PAGE_SIZE = 20; // Number of rows per page

const MaintenanceTable = ({ data }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortType, setSortType] = useState(null);

    // Handle sorting

    // Handle pagination
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Sort data if a sort column is specified
    const sortedData = React.useMemo(() => {
        if (!sortColumn || !sortType) {
            return data;
        }

        console.log('Sorting by:', sortColumn, 'Type:', sortType); // Debugging log

        return [...data].sort((a, b) => {
            let x = a[sortColumn];
            let y = b[sortColumn];

            // Handle null or undefined values
            if (x == null) x = '';
            if (y == null) y = '';

            // Handle date sorting
            if (sortColumn === 'date') {
                x = moment(x);
                y = moment(y);
                return sortType === 'asc' ? x - y : y - x;
            }

            // Handle string comparison
            if (typeof x === 'string' && typeof y === 'string') {
                x = x.toLowerCase();
                y = y.toLowerCase();
            }

            // Handle numeric comparison
            if (sortType === 'asc') {
                return x > y ? 1 : x < y ? -1 : 0;
            } else {
                return x < y ? 1 : x > y ? -1 : 0;
            }
        });
    }, [data, sortColumn, sortType]);

    // Paginate data
    const paginatedData = sortedData.slice(
        (currentPage - 1) * PAGE_SIZE,
        currentPage * PAGE_SIZE
    );

// Handle column sort event
    const handleSortColumn = (column, type) => {
        console.log('Sorting column:', column, 'Type:', type); // Debugging log
        setSortColumn(column);
        setSortType(type);
    };

    return (
        <div>
            <Table
                autoHeight
                data={paginatedData}
                onSortColumn={handleSortColumn}
                sortColumn={sortColumn}
                sortType={sortType}
            >
                <Table.Column width={200} align="center" sortable>
                    <Table.HeaderCell>PLAKA</Table.HeaderCell>
                    <Table.Cell dataKey="PLAKA" />
                </Table.Column>

                <Table.Column width={150} align="center" sortable>
                    <Table.HeaderCell>Tarih</Table.HeaderCell>
                    <Table.Cell dataKey={'date'}>
                        {(rowData) => moment(rowData.date).format('YYYY-MM-DD')}
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={150} align="center" sortable>
                    <Table.HeaderCell>KM</Table.HeaderCell>
                    <Table.Cell dataKey={'BAKIM KM'}>
                        {(rowData) => formatKM(rowData["BAKIM KM"])}
                    </Table.Cell>
                </Table.Column>

                <Table.Column width={100} align="right" sortable>
                    <Table.HeaderCell>Tutar</Table.HeaderCell>
                    <Table.Cell dataKey={'amount'}>
                        {(rowData) => {
                            if (rowData.amount !== undefined && rowData.amount !== null) {
                                return formatTL(rowData.amount);
                            }
                            return "₺ 0,00";
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1} align="center" sortable>
                    <Table.HeaderCell>Servis</Table.HeaderCell>
                    <Table.Cell dataKey="SERVİS" />
                </Table.Column>

                <Table.Column flexGrow={2} align="center">
                    <Table.HeaderCell>Notlar</Table.HeaderCell>
                    <Table.Cell dataKey="NOTLAR" />
                </Table.Column>
            </Table>
            {/* Pagination */}
            <Pagination
                style={{ marginTop: 10, textAlign: 'center' }}
                total={data.length}
                limit={PAGE_SIZE}
                activePage={currentPage}
                onChangePage={handlePageChange}
            />
        </div>
    );
};


export default MaintenanceScreen;
