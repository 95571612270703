'use client';
import { SchemaModel, ObjectType } from 'schema-typed';
import { useRef, useCallback } from 'react';
/**
 * Convert a flat schema object to a nested schema object
 *
 * @example
 *
 * ```js
 * const schema = {
 *  'address.city': StringType().isRequired('City is required'),
 *  'address.street': StringType().isRequired('Street is required')
 * };
 *
 * const result = unflattenSchemaObject(schema);
 *
 * // result
 * {
 *    address: ObjectType().shape({
 *      city: StringType().isRequired('City is required'),
 *      street: StringType().isRequired('Street is required')
 *    })
 * }
 * ```
 */
function unflattenSchemaObject(schema) {
  var result = {};
  var $type = Symbol('schema-type');
  Object.keys(schema).forEach(function (key) {
    if (key.includes('.')) {
      var keys = key.split('.');
      var lastKey = keys.pop() || '';
      var current = result;
      keys.forEach(function (subKey) {
        var _ref;
        current[subKey] = current[subKey] || (_ref = {}, _ref[$type] = 'object-type', _ref);
        current = current[subKey];
      });
      current[lastKey] = schema[key];
    } else {
      result[key] = schema[key];
    }
  });
  function convertToShape(obj) {
    Object.keys(obj).forEach(function (key) {
      var _obj$key;
      if (((_obj$key = obj[key]) === null || _obj$key === void 0 ? void 0 : _obj$key[$type]) === 'object-type') {
        delete obj[key][$type];
        obj[key] = ObjectType().shape(convertToShape(obj[key]));
      }
    });
    return obj;
  }
  return convertToShape(result);
}
function useSchemaModel(formModel, nestedField) {
  var subRulesRef = useRef([]);
  var pushFieldRule = useCallback(function (name, fieldRule) {
    subRulesRef.current.push({
      name: name,
      fieldRule: fieldRule
    });
  }, []);
  var removeFieldRule = useCallback(function (name) {
    var index = subRulesRef.current.findIndex(function (v) {
      return v.name === name;
    });
    subRulesRef.current.splice(index, 1);
  }, []);
  var getCombinedModel = useCallback(function () {
    var realSubRules = subRulesRef.current.filter(function (v) {
      return Boolean(v.fieldRule.current);
    });

    // If there is no sub rule, return the original form model
    if (realSubRules.length === 0) {
      return formModel;
    }
    var subRuleObject = realSubRules.map(function (_ref2) {
      var _ref3;
      var name = _ref2.name,
        fieldRule = _ref2.fieldRule;
      return _ref3 = {}, _ref3[name] = fieldRule.current, _ref3;
    }).reduce(function (a, b) {
      return Object.assign(a, b);
    }, {});
    return SchemaModel.combine(formModel, SchemaModel(nestedField ? unflattenSchemaObject(subRuleObject) : subRuleObject));
  }, [formModel, nestedField]);
  return {
    getCombinedModel: getCombinedModel,
    pushFieldRule: pushFieldRule,
    removeFieldRule: removeFieldRule
  };
}
export default useSchemaModel;