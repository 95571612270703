'use client';
import { createComponent } from "../internals/utils/index.js";
/**
 * The `<Footer>` component is used to specify the footer of the page.
 * @see https://rsuitejs.com/components/container/
 */
var Footer = createComponent({
  name: 'Footer',
  componentAs: 'footer'
});
export default Footer;