'use client';
import { createComponent } from "../internals/utils/index.js";
/**
 * HeadingGroup is a group of headings.
 * @see https://rsuitejs.com/components/heading
 */
var HeadingGroup = createComponent({
  name: 'HeadingGroup',
  componentAs: 'hgroup'
});
export default HeadingGroup;