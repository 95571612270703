import { Text, View, StyleSheet, TouchableOpacity,FlatList} from 'react-native'
import React, {  useState ,useEffect } from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {useToaster, Message, Button,Modal ,Form ,DatePicker,TreePicker ,SelectPicker ,InputNumber, IconButton, List, Drawer  } from 'rsuite';
import configdata from '../../config/config.json';
import { useUserToken } from '../../hooks/useUserToken';
import axios from 'axios';
import { Icon, MiniProgram } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
;
import { useRef } from 'react';
// import FolderManagementSystem from '../Components/FolderManagementSystem';
import { ScrollView } from 'react-native-web';
import { FileUploader } from 'react-drag-drop-files';
import FileLoader from '../../Components/FileManagements/FileLoader';
import FileViewer from '../../Components/FileManagements/FileViewer';
import TrafficInsurenceScreen from './CarTabScreens/TrafficInsurenceScreen';
import KaskoScreen from './CarTabScreens/KaskoScreen';
import HGSScreen from './CarTabScreens/HGSScreen';
import KmScreen from './CarTabScreens/KmScreen';
import MaintenanceScreen from './CarTabScreens/MaintenanceScreen';
import ExhaustScreen from './CarTabScreens/ExhaustService';
import CarExeminationScreen from './CarTabScreens/CarExeminationScreen';
import ShowCurrentPanel from '../../Components/CarAndFileManagement/ShowSpecsGrid';
import BuyInfoScreen from './CarTabScreens/BuyInfoScreen';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';

import KaskoValueScreen from './CarTabScreens/KaskoValueScreen';
import GeneralCarInfo from './CarTabScreens/Generalnfo';
import CarServiceScreen from './CarTabScreens/CarServiceScreen';
import SellInfoScreen from './CarTabScreens/SellInfoScreen';
import ResponsiveNav from '@rsuite/responsive-nav';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CarDamageReportScreen from './CarTabScreens/CarDamageReportScreen';


const  CarInfoScreen = (props) =>   {

  const [refresh,setRefresh] = useState(false);
  const [data,setData] = useState(props.data);
  const genRef = useRef();

  function reset(){
      props.reset();
      setRefresh(!refresh);
      console.log("reset");
      if(genRef.current)
      {
        genRef.current.refresh();

      }
  }


  const GeneralCarInfoRender = () => (<GeneralCarInfo  ref = {genRef} reset={reset} data = {props.data}></GeneralCarInfo>)
  const TrafficInsurencePanel = () => (<TrafficInsurenceScreen reset={reset} data = {props.data} ></TrafficInsurenceScreen>)
  const KaskoInsurencePanel = () => (<KaskoScreen reset={reset} data = {props.data} ></KaskoScreen>)
  const HgsScreen = () => (<HGSScreen reset={reset} data = {props.data} ></HGSScreen>)
  const KmScreenComp = () => (<KmScreen reset={reset} data = {props.data} ></KmScreen>)
  const MaintenanceScreenComp = () => (<MaintenanceScreen reset={reset} data = {props.data} ></MaintenanceScreen>)
  const ExhaustScreenComp = () => (<ExhaustScreen reset={reset} data = {props.data} ></ExhaustScreen>)
  const ExeminationComp = () => (<CarExeminationScreen reset={reset} data = {props.data} ></CarExeminationScreen>)
  const KaskoValueComp = () => (<KaskoValueScreen reset={reset} data = {props.data} ></KaskoValueScreen>)
  const BuyInfoComp = () => (<BuyInfoScreen reset={reset} data = {props.data} ></BuyInfoScreen>)
  // const ServiceComp = () => (<CarServiceScreen reset={reset} data = {props.data} ></CarServiceScreen>)
  const SellInfoComp = () => (<SellInfoScreen reset={reset} data = {props.data} ></SellInfoScreen>)
  const HasarComp = () => (<CarDamageReportScreen reset={reset} data = {props.data} ></CarDamageReportScreen>)

  const [TabList, setArray] = useState([    {
      id: "1",
      name: "Genel",
      icon: "car",
      component: GeneralCarInfoRender
    },
    {
      id: "2",
      name: "Kasko",
      icon: "shield-crown",
      component: KaskoInsurencePanel
    },
    {
      id: "3",
      name: "Trafik Sigorta",
      icon: "shield-check",
      component: TrafficInsurencePanel
    },
    {
      id: "4",
      name: "KM Bilgileri",
      icon: "speedometer",
      component: KmScreenComp
    },
    {
      id: "5",
      name: "HGS/OGS",
      icon: "road-variant",
      component: HgsScreen
    },
    {
      id: "6",
      name: "Bakım",
      icon: "clipboard-check-multiple-outline",
      component: MaintenanceScreenComp
    },
    // {
    //   id: "7",
    //   name: "Servis",
    //   icon: "clipboard-check-multiple-outline",
    //   component : ServiceComp
    // },
    {
      id: "13",
      name: "Hasar",
      icon: "clipboard-check-multiple-outline",
      component: HasarComp
    },
    {
      id: "8",
      name: "Egzoz Muayene",
      icon: "clipboard-check-multiple-outline",
      component: ExhaustScreenComp
    },
    {
      id: "9",
      name: "Muayene",
      icon: "clipboard-check-multiple-outline",
      component: ExeminationComp
    },
    {
      id: "10",
      name: "Kasko Değeri",
      icon: "clipboard-check-multiple-outline",
      component: KaskoValueComp
    },
    {
      id: "11",
      name: "Satın Alım",
      icon: "clipboard-check-multiple-outline",
      component: BuyInfoComp
    },
    {
      id: "12",
      name: "Satış",
      icon: "clipboard-check-multiple-outline",
      component: SellInfoComp
    },

  ]);

  const [activeKey, setActiveKey] = useState("Genel");

  return (

      <View style={{backgroundColor: '#F2F2F2'}}>
        <ResponsiveNav activeKey={activeKey} onSelect={setActiveKey} appearance='default' style={{backgroundColor: 'white'}}>
          {TabList.map(item => (
            <ResponsiveNav.Item key={item.name} eventKey={item.name}>
              {item.name}
            </ResponsiveNav.Item>
          ))}
      </ResponsiveNav>
      <View style = {styles.container}>
          <View style={{width: '100%'}}>
            {activeKey === "Genel" && <GeneralCarInfo  ref = {genRef} reset={reset} data = {props.data}></GeneralCarInfo>}
            {activeKey === "Kasko" && <KaskoScreen reset={reset} data = {props.data} ></KaskoScreen>}
            {activeKey === "Trafik Sigorta" && <TrafficInsurenceScreen reset={reset} data = {props.data} ></TrafficInsurenceScreen>}
            {activeKey === "KM Bilgileri" && <KmScreen reset={reset} data = {props.data} ></KmScreen>}
            {activeKey === "HGS/OGS" && <HGSScreen reset={reset} data = {props.data} ></HGSScreen>}
            {activeKey === "Bakım" && <MaintenanceScreen reset={reset} data = {props.data} ></MaintenanceScreen>}
            {activeKey === "Servis" && <CarServiceScreen reset={reset} data = {props.data} ></CarServiceScreen>}
            {activeKey === "Hasar" && <CarDamageReportScreen reset={reset} data = {props.data} ></CarDamageReportScreen>}
            {activeKey === "Egzoz Muayene" && <ExhaustScreen reset={reset} data = {props.data} ></ExhaustScreen>}
            {activeKey === "Muayene" && <CarExeminationScreen reset={reset} data = {props.data} ></CarExeminationScreen>}
            {activeKey === "Kasko Değeri" && <KaskoValueScreen reset={reset} data = {props.data} ></KaskoValueScreen>}
            {activeKey === "Satın Alım" && <BuyInfoScreen reset={reset} data = {props.data} ></BuyInfoScreen>}
            {activeKey === "Satış" && <SellInfoScreen reset={reset} data = {props.data} ></SellInfoScreen>}
          </View>
          </View>
      </View>

    )

}




const styles = StyleSheet.create(
  {
    container:{
      flex:1,
      alignItems:'center',
      width: "100%",
      backgroundColor :'#F2F2F2'
    },
    bottomBar: {
      height: 60,
      backgroundColor: 'white',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent:'flex-start',
      borderBottomColor :'#00589B',
      borderBottomWidth: 1
    },
    card:{
      backgroundColor: 'white',
      padding: 13, borderRadius: 12,height: 80 ,
      flexDirection : 'row', marginVertical: 20 ,shadowColor: '#BDC9FF',
      shadowOffset: { width: 0, height: 1 }, shadowOpacity: 0.3, shadowRadius: 12, elevation: 5
    }
  }
)

export default CarInfoScreen


