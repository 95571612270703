'use client';
import { useState } from 'react';
import useUpdateEffect from './useUpdateEffect';
import flattenData from './flattenData';
import findAllParents from './findAllParents';
import shouldShowRowByExpanded from './shouldShowRowByExpanded';
import { EXPANDED_KEY, TREE_DEPTH } from '../constants';
/**
 * Filter those expanded nodes.
 * @param data
 * @param expandedRowKeys
 * @param rowKey
 * @returns
 */
var filterTreeData = function filterTreeData(data, expandedRowKeys, rowKey) {
  return flattenData(data).filter(function (rowData) {
    if (rowKey) {
      var parents = findAllParents(rowData, rowKey);
      var expanded = shouldShowRowByExpanded(expandedRowKeys, parents);

      // FIXME This function is supposed to be pure.
      //       Don't mutate rowData in-place!
      rowData[EXPANDED_KEY] = expanded;
      rowData[TREE_DEPTH] = parents.length;
      return expanded;
    }
  });
};
function useTableData(props) {
  var data = props.data,
    isTree = props.isTree,
    expandedRowKeys = props.expandedRowKeys,
    rowKey = props.rowKey;
  var _useState = useState(function () {
      return isTree ? filterTreeData(data, expandedRowKeys, rowKey) : data;
    }),
    tableData = _useState[0],
    setData = _useState[1];
  useUpdateEffect(function () {
    setData(isTree ? filterTreeData(data, expandedRowKeys, rowKey) : data);
  }, [data, expandedRowKeys, rowKey, isTree]);
  return tableData;
}
export default useTableData;