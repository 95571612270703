'use client';
import { createComponent } from "../internals/utils/index.js";
/**
 * The `TagGroup` component for use with the `Tag` component.
 *
 * @see https://rsuitejs.com/components/tag/
 */
var TagGroup = createComponent({
  name: 'TagGroup'
});
export default TagGroup;