'use client';
import { createComponent } from "../internals/utils/index.js";
/**
 * The `<Header>` component is used to specify the header of the page.
 * @see https://rsuitejs.com/components/container/
 */
var Header = createComponent({
  name: 'Header',
  componentAs: 'header'
});
export default Header;