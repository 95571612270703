import { Text, View, StyleSheet } from 'react-native';
import React, { useState } from 'react';
import {
    useToaster,
    Message,
    IconButton,
    Table,
    Loader,
    SelectPicker
} from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@rsuite/icons';
import FileUploader from '../../Components/FileManagements/FileUploader';
import ExportData from '../../Components/TableManagement/ExportData';

import configdata from '../../config/config.json';
import axios from 'axios';
import * as XLSX from 'xlsx';
import {useUserToken} from "../../hooks/useUserToken";

// === 1. Validation Maps ===================================
//   columns relevant to CAR, BUY, SELL updates
const carValidationMap = {
    PLAKA:             { type: 'PLAKA' },
    ŞASİ_NO:           { type: 'STRING_REQUIRED' },
    TESCİL_TARİHİ:     { type: 'DATE' }
    // ... Add more as needed for the CARS table
};

const buyValidationMap = {
    CAR_ID:            { type: 'STRING_REQUIRED' },
    CLIENT_ID:         { type: 'STRING_OPTIONAL' },
    OFFICIAL_BUY_DATE: { type: 'DATE' },
    OTV:               { type: 'NUMBER' }
    // ... Add more as needed for the CAR_BUY_INFOS table
};

const sellValidationMap = {
    CAR_ID:        { type: 'STRING_REQUIRED' },
    SELL_DATE:     { type: 'DATE' },
    SELL_CLIENT:   { type: 'STRING_OPTIONAL' },
    TAX_EXC_PRICE: { type: 'NUMBER' },
    TAX_INC_PRICE: { type: 'NUMBER' }
    // ... Add more as needed for the CAR_SELL_INFOS table
};

function getValidationMap(updateType) {
    switch (updateType) {
        case 'CAR':  return carValidationMap;
        case 'BUY':  return buyValidationMap;
        case 'SELL': return sellValidationMap;
        default:     return {}; // no validations
    }
}

// === 2. Validation Helpers ====================================
function validateCell(cellValue, rule) {
    // If cell is empty => skip => no error (treat as optional)
    if (
        cellValue === undefined ||
        cellValue === null ||
        String(cellValue).trim() === ''
    ) {
        return { isValid: true };
    }

    switch (rule.type) {
        case 'STRING_REQUIRED':
            if (!String(cellValue).trim()) {
                return { isValid: false, message: 'Bu alan zorunludur.' };
            }
            return { isValid: true };

        case 'PLAKA':
            // Example: check min length, or any pattern you want
            if (String(cellValue).length < 5) {
                return { isValid: false, message: 'Plaka en az 5 karakter olmalı.' };
            }
            return { isValid: true };

        case 'DATE':
            // Convert an Excel-based date or string to Date
            const dateObj = parseExcelDate(cellValue);
            if (!dateObj || isNaN(dateObj.getTime())) {
                return { isValid: false, message: 'Geçersiz tarih formatı.' };
            }
            return { isValid: true };

        case 'NUMBER':
            const numVal = parseFloat(
                String(cellValue).replace('TL', '').replace(',', '.')
            );
            if (isNaN(numVal)) {
                return { isValid: false, message: 'Geçersiz sayı.' };
            }
            return { isValid: true };

        case 'STRING_OPTIONAL':
        default:
            // no validation needed
            return { isValid: true };
    }
}

// Excel date parse helper
function parseExcelDate(value) {
    // If numeric, interpret as days from 1900
    if (typeof value === 'number' && !isNaN(value)) {
        const epoch = new Date(Date.UTC(1900, 0, 1));
        // Excel incorrectly starts at 1900-01-00, so subtract 2 days
        return new Date(epoch.getTime() + (value - 2) * 86400000);
    }
    // Try normal date parse
    const d = new Date(value);
    return isNaN(d.getTime()) ? null : d;
}

// === 3. MAIN COMPONENT ===================================
const MultipleCarUpdate = () => {
    const toaster = useToaster();

    const [updateType, setUpdateType] = useState('CAR'); // "CAR" | "BUY" | "SELL"
    const [wrongRows, setWrongRows] = useState([]);
    const [correctRows, setCorrectRows] = useState([]);

    // For displaying success/error:
    function messageToggle(text, type) {
        toaster.push(
            <Message showIcon type={type}>
                {text}
            </Message>,
            { placement: 'topEnd' }
        );
    }

    // === 3.1 Handle XLSX File Upload ========================
    const handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];

            // Convert to 2D array
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            if (!data || data.length < 1) {
                messageToggle('Excel dosyasında veri yok.', 'warning');
                return;
            }

            // First row are headers
            const headers = data[0];
            // Subsequent rows are data
            const rows = data.slice(1);

            // Filter out completely empty rows
            const nonEmptyRows = rows.filter((r) => r.some((c) => c != null && c !== ''));

            const valMap = getValidationMap(updateType);

            let valCorrect = [];
            let valWrong = [];

            // For each row
            nonEmptyRows.forEach((row, idx) => {
                const rowObj = {};
                rowObj['Fix Needed'] = '';
                rowObj['ID'] = idx; // or a real ID if you have one in data
                // Build the row object from headers
                headers.forEach((colName, i) => {
                    rowObj[colName] = row[i];
                });

                // Apply validation
                Object.keys(valMap).forEach((col) => {
                    const rule = valMap[col];
                    const cellValue = rowObj[col];
                    const result = validateCell(cellValue, rule);
                    if (!result.isValid) {
                        if (rowObj['Fix Needed'] === '') {
                            rowObj['Fix Needed'] = `Hatalı ${col}`;
                        } else {
                            rowObj['Fix Needed'] += `, Hatalı ${col}`;
                        }
                    }
                });

                // Separate into correct vs. needs fix
                if (rowObj['Fix Needed'] === '') {
                    valCorrect.push(rowObj);
                } else {
                    valWrong.push(rowObj);
                }
            });

            setCorrectRows(valCorrect);
            setWrongRows(valWrong);
        };

        reader.readAsBinaryString(file);
    };

    // === 3.2 Build the final data for sending ===============
    function buildPayload(rows) {
        if(updateType === 'CAR') {
            return rows.map((row) => {
                return {
                    PLAKA: row.PLAKA,
                    ŞASİ_NO: row.ŞASİ_NO,
                    TESCİL_TARİHİ: row.TESCİL_TARİHİ
                };
            });
        }else if(updateType === 'BUY') {
            return rows.map((row) => {
                return {
                    CAR_ID: row.CAR_ID,
                    CLIENT_ID: row.CLIENT_ID,
                    OFFICIAL_BUY_DATE: row.OFFICIAL_BUY_DATE,
                    OTV: row.OTV
                };
            });
        }
        else if (updateType === 'SELL') {
            return rows.map((row) => {
                return {
                    CAR_ID: row.CAR_ID,
                    SELL_DATE: parseExcelDate(row.SELL_DATE),
                    SELL_CLIENT: row.SELL_CLIENT,
                    STAFF: row.STAFF,
                    TAX_EXC_PRICE: row.TAX_EXC_PRICE,
                    TAX_INC_PRICE: row.TAX_INC_PRICE,
                    FINAL_PRICE: row.FINAL_PRICE,
                    KM: row.KM,
                    INVOICE_NO: row.INVOICE_NO
                };
            });
        }
    }

    function parseExcelDate(value) {
        // If numeric, interpret as days from 1900
        if (typeof value === 'number' && !isNaN(value)) {
            const epoch = new Date(Date.UTC(1900, 0, 1));
            // Excel incorrectly starts at 1900-01-00, so subtract 2 days
            return new Date(epoch.getTime() + (value - 2) * 86400000);
        }
        // Try normal date parse
        const d = new Date(value);
        return isNaN(d.getTime()) ? null : d;
    }

    // Decide which "type" to send in the dataHeader
    function getBackendType(updateType) {
        switch (updateType) {
            case 'CAR':  return 'UPDATE_MULTIPLE_CAR';
            case 'BUY':  return 'UPDATE_MULTIPLE_BUY';
            case 'SELL': return 'UPDATE_MULTIPLE_SELL_INFO';
            default:     return 'UNKNOWN_TYPE';
        }
    }

    const { fetchIdToken } = useUserToken();

    // === 3.3 Send Data to Backend ===========================
    const [isLoading, setIsLoading] = useState(false);
    const sendData = async () => {
        if (correctRows.length === 0) {
            messageToggle('Geçerli satır yok, gönderilemiyor.', 'warning');
            return;
        }
        setIsLoading(true);

        const exportData = buildPayload(correctRows);
        const dataHeader = {
            ENV: configdata.APP_ENV,
            type: getBackendType(updateType),
            data: exportData
        };

        try {
            // You might have an auth token or something:
            const idToken = await fetchIdToken(); // if you have that


            const api = configdata.API_BASE + '/cardata';
            const headers = {
                Authorization: `Bearer ${idToken}`
            };

            console.log(dataHeader);


            const response = await axios.post(api, dataHeader, { headers });
            if (response.data !== 'SUCCESS') {
                console.error(response.data);
                messageToggle('Satırlar güncellenemedi, lütfen verileri kontrol edin.', 'error');
            } else {
                messageToggle('Satırlar başarıyla güncellendi!', 'success');
            }
        } catch (err) {
            console.error(err);
            messageToggle('Sunucuya gönderilirken hata oluştu.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    // === 4. UI: Two Tables (invalid + valid) + upload button =
    // We'll reuse your style of "white container" plus a shadow, etc.
    const columns = [
        { key: 'PLAKA', name: 'PLAKA' },
        { key: 'ŞASİ_NO', name: 'ŞASİ NO' },
        { key: 'TESCİL_TARİHİ', name: 'TESCİL TARİHİ' },
        { key: 'Fix Needed', name: 'Düzeltme Gerekiyor' }
        // add more if you want to display them in the table
    ];

    const columnsBuy = [
        { key: 'CAR_ID', name: 'CAR_ID' },
        { key: 'CLIENT_ID', name: 'CLIENT_ID' },
        { key: 'OFFICIAL_BUY_DATE', name: 'OFFICIAL_BUY_DATE' },
        { key: 'OTV', name: 'OTV' },
        { key: 'Fix Needed', name: 'Düzeltme Gerekiyor' },
    ];

    const columnsSell = [
        { key: 'CAR_ID', name: 'CAR_ID' },
        { key: 'PLAKA' , name: 'PLAKA'},
        { key: 'SELL_DATE', name: 'SELL_DATE' },
        { key: 'SELL_CLIENT', name: 'SELL_CLIENT' },
        { key: 'STAFF', name: 'STAFF'},
        { key: 'TAX_EXC_PRICE', name: 'TAX_EXC_PRICE' },
        { key: 'TAX_INC_PRICE', name: 'TAX_INC_PRICE' },
        { key: 'FINAL_PRICE', name: 'FINAL_PRICE' },
        { key: 'KM', name: 'KM' },
        { key : 'INVOICE_NO', name: 'INVOICE_NO'},
        { key: 'Fix Needed', name: 'Düzeltme Gerekiyor' }
    ];


    // A custom cell for printing data
    const CustomCell = ({ rowData, dataKey, ...props }) => {
        return (
            <Table.Cell {...props} style={{ padding: 4 }}>
                <View style={{ justifyContent: 'center' }}>
                    <Text>{rowData[dataKey]}</Text>
                </View>
            </Table.Cell>
        );
    };

    return (
        <View style={{ alignItems: 'center', backgroundColor: '#FFF', minHeight: '100%', paddingTop: 20 }}>
            {/* Select which update type */}
            <View style={[mainStyles.shadow, { width: '95%', backgroundColor: '#FFF', borderRadius: 6, padding: 10 }]}>
                <Text style={mainStyles.textStandard}>Güncelleme Türü Seç</Text>
                <SelectPicker
                    data={[
                        { label: 'Car Update', value: 'CAR' },
                        { label: 'Buy Update', value: 'BUY' },
                        { label: 'Sell Update', value: 'SELL' }
                    ]}
                    style={{ width: 200 }}
                    value={updateType}
                    onChange={(val) => setUpdateType(val)}
                />
            </View>

            {/* File Uploader */}
            <View
                style={[
                    mainStyles.shadow,
                    { backgroundColor: 'white', borderRadius: 5, width: '95%', height: 100, flexDirection: 'row', marginVertical: 10, alignItems: 'center', justifyContent: 'space-evenly' }
                ]}
            >
                <FileUploader handleChange={handleFileUpload} name="file" types={['XLSX', 'XLS']} />
            </View>

            {/* Table for Wrong Rows */}
            <View style={[mainStyles.shadow, { alignItems: 'center', marginVertical: 15, width: '95%', backgroundColor: 'white', borderRadius: 12, padding: 15 }]}>
                <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                    <Text style={mainStyles.textStandard}>Hatalı Veri Bulunan Satırlar</Text>
                    <ExportData data={wrongRows} />
                </View>
                <View style={{ width: '100%', height: wrongRows.length > 2 ? 400 : 150 }}>
                    <Table data={wrongRows} height={wrongRows.length > 2 ? 400 : 150} bordered cellBordered>
                        {columns.map((col) => (
                            <Table.Column key={col.key} resizable sortable>
                                <Table.HeaderCell>{col.name}</Table.HeaderCell>
                                <CustomCell dataKey={col.key} />
                            </Table.Column>
                        ))}
                    </Table>
                </View>
            </View>

            {/* Table for Correct Rows */}
            <View style={[mainStyles.shadow, { alignItems: 'center', marginVertical: 15, width: '95%', backgroundColor: 'white', borderRadius: 12, padding: 15 }]}>
                <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                    <Text style={mainStyles.textStandard}>Yüklenebilir Satırlar</Text>
                    <ExportData data={correctRows} />
                </View>
                <View style={{ width: '100%', height: correctRows.length > 2 ? 400 : 150 }}>
                    <Table data={correctRows} height={correctRows.length > 2 ? 400 : 150} bordered cellBordered>
                        {updateType === 'CAR' && columns.map((col) => (
                            <Table.Column key={col.key} resizable sortable>
                                <Table.HeaderCell>{col.name}</Table.HeaderCell>
                                <CustomCell dataKey={col.key} />
                            </Table.Column>
                        ))}
                        {updateType === 'BUY' && columnsBuy.map((col) => (
                            <Table.Column key={col.key} resizable sortable>
                                <Table.HeaderCell>{col.name}</Table.HeaderCell>
                                <CustomCell dataKey={col.key} />
                            </Table.Column>
                        ))}
                        {updateType === 'SELL' && columnsSell.map((col) => (
                            <Table.Column key={col.key} resizable sortable>
                                <Table.HeaderCell>{col.name}</Table.HeaderCell>
                                <CustomCell dataKey={col.key} />
                            </Table.Column>
                        ))}
                    </Table>
                </View>

                {/* Upload Button */}
                <View style={{ flexDirection: 'row', marginTop: 20 }}>
                    <IconButton
                        disabled={isLoading}
                        style={{ width: 200, marginRight: 10 }}
                        onClick={sendData}
                        icon={
                            <Icon>
                                <FontAwesomeIcon icon="upload" />
                            </Icon>
                        }
                    >
                        UPLOAD
                    </IconButton>
                    {isLoading && <Loader />}
                </View>
            </View>
        </View>
    );
};

export default MultipleCarUpdate;

const mainStyles = StyleSheet.create({
    textStandard: {
        fontSize: 16,
        fontWeight: '500',
        marginBottom: 5
    },
    shadow: {
        shadowColor: '#BDC9FF',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.2,
        shadowRadius: 12,
        elevation: 5
    }
});
