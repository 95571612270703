'use client';
function _createForOfIteratorHelperLoose(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import React from 'react';
import * as ReactIS from 'react-is';
var _flattenChildren = function flattenChildren(children, flattened) {
  if (flattened === void 0) {
    flattened = [];
  }
  for (var _iterator = _createForOfIteratorHelperLoose(React.Children.toArray(children)), _step; !(_step = _iterator()).done;) {
    var child = _step.value;
    if (ReactIS.isFragment(child)) {
      var _childEl$props;
      var childEl = child;
      if ((_childEl$props = childEl.props) !== null && _childEl$props !== void 0 && _childEl$props.children) {
        _flattenChildren(childEl.props.children, flattened);
      }
    } else {
      flattened.push(child);
    }
  }
  return flattened;
};
export { _flattenChildren as flattenChildren };